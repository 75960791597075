.banner_wrapper {
  height: 100vh;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
      rgba(48, 48, 48, 0.81),
      rgba(48, 48, 48, 0.81)
    ),
    url(./images/lkMain.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  @media only screen and (max-width: 765px) {
    position: fixed;
    left: 0;
    right: 0;
    width: 100vw;
    background-image: linear-gradient(
        rgba(48, 48, 48, 0.81),
        rgba(48, 48, 48, 0.81)
      ),
      url(./images/lkMainMobile.jpg);
  }
}

.logo {
  margin: 6px 56px 0 0;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width: 1108px) {
    width: 240px;
  }
  @media only screen and (max-width: 765px) {
    display: none;
  }
}
