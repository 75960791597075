.section-header {
  border-bottom: 1px solid black;
  background-color: #303030;
  height: 106px;

  @media only screen and (max-width: 1108px) {
    height: auto;
    padding: 0 65px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 29px 0;

  @media only screen and (max-width: 1108px) {
    max-width: 765px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: 765px) {
    padding-bottom: 19px;
    padding-top: 24px;
  }
}

.header__section {
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}

.logo {
  width: 92px;
  height: 50px;
  margin-right: 52px;
  fill: #fff;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
    transform: scale(1.05)
  }

  @media only screen and (max-width: 1108px) {
    width: 64px;
    height: 35px;
    margin-right: 34px;
  }

  @media only screen and (max-width: 765px) {
    width: 55px;
    height: 30px;
  }

  &-container {
    display: flex;
    justify-content: center;
  }
}

.header__menu {
  display: flex;
  margin: 0;
  padding: 0;

  @media only screen and (max-width: 1108px) {
    justify-content: space-between;
  }

  @media only screen and (max-width: 765px) {
    display: none;
  }
}

.header__item {
  flex-shrink: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  margin-right: 42px;
  cursor: pointer;
  transition: 0.3s;

  @media only screen and (max-width: 1108px) {
    margin-right: 42px;
  }
}

.header__item-link {
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 130%;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &__current {
    color: #be763c;
  }

  &:hover,
  &:focus,
  &:active {
    transform: scale(1.05);
    color: #be763c;
  }
}

.headerUser {
  margin-right: 20px;
  transition: color 0.2s ease-in-out;

  @media only screen and (max-width: 1108px) {
    display: none;
  }

  &:hover {
    color: #be763c;
  }
}

.headerButton {
  width: 39px;
  height: 39px;
  background-color: #be763c;
  border-radius: 50%;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    background-color: #ac662d;
  }
}

.popover {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px 50px 0px 12px;

  &-link {
    cursor: pointer;
    color: #303030;
    transition: color 0.2s ease-in-out;

    &:not(:last-child) {
      padding-bottom: 10px;
    }

    &:hover,
    &:focus,
    &:active {
      color: #be763c;
    }
  }
}

.userContainer {
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 765px) {
    display: none;
  }
}

.hamburgerBtn {
  display: none;
  cursor: pointer;
  width: 30px;
  height: 14px;
  background: url('./images/hanburger.svg') center no-repeat;

  @media only screen and (max-width: 765px) {
    display: block;
  }
}