.how-use-section {
  padding: 78px 0;
  color: white;
  background-color: #1d1d1b;

  @media only screen and (max-width: 1108px) {
    padding: 55px 0;
  }

  @media only screen and (max-width: 765px) {
    padding: 36px 0;
  }
}

.how-use-section__title {
  font-size: 35px;
  font-weight: 700;
  line-height: 130.5%;
  text-transform: uppercase;
  text-align: start;
  line-height: 46px;
  margin: 6px 0 68px 0;

  @media only screen and (max-width: 1108px) {
    font-size: 25px;
    line-height: normal;
    margin-bottom: 25px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 20px;
    margin-bottom: 36px;
  }
}

.how-use-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1rf);

  @media only screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
  }
}

.rules {
  grid-column: 2;
  grid-row: 1 /5;

  @media only screen and (max-width: 765px) {
    margin-left: 0;
    margin-bottom: 28px;
  }
}

.rules-item {
  display: flex;
  align-items: flex-start;
  padding-bottom: 29px;
  margin-bottom: 31px;
  border-bottom: 2px solid #c0c0c0;

  &:last-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 1108px) {
    padding-bottom: 20px;
    margin-bottom: 9px;
  }
}

.rules-item__count {
  font-weight: 700;
  font-size: 40px;
  margin: 17px 24px 0 0;
  align-self: start;

  @media only screen and (max-width: 1108px) {
    font-size: 30px;
    margin: 12px 12px 0 12px;
  }
}

.rules-item__text {
  margin-top: 15px;

  @media only screen and (max-width: 1108px) {
    margin-top: 12px;
  }
}

.rules-item__title {
  font-weight: 700;
  font-size: 18px;
  margin: 0 0 12px 0;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
    margin-bottom: 3px;
  }
}

.rules-item__subtitle {
  font-weight: 400;
  font-size: 16px;
  color: #c0c0c0;
  margin: 0;

  @media only screen and (max-width: 1108px) {
    margin-bottom: 19px;
    font-size: 14px;
  }
}

.how-use-section__tip {
  display: flex;
  align-items: flex-start;
  grid-column: 1;

  padding-right: 112px;

  @media only screen and (max-width: 1108px) {
    flex-direction: column;
    padding-right: 30px;
  }

  @media only screen and (max-width: 765px) {
    padding-right: 0;
    margin-bottom: 33px;
  }

  &-text {
    margin: 0;
    font-size: 16px;
    line-height: 150%;

    @media screen and (max-width: 1108px) {
      font-size: 14px;
    }
  }
}

.how-use-section__tip-icon {
  margin-top: -17px;
  font-size: 54px;
  margin-right: 10px;

  @media only screen and (max-width: 1108px) {
    font-size: 34px;
    line-height: 14px;
    margin: 0;
  }
}

.how-use-section__tip-icon--question {
  @media only screen and (max-width: 1108px) {
    line-height: normal;
  }
}

.how-use-description {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.how-use-section__tip--bottom {
  grid-column: 1;
  order: 1;
  grid-row: 4;
}

.how-use-section__tip-subtitle {
  display: block;
  margin-bottom: 18px;

  @media only screen and (max-width: 1108px) {
    line-height: 18px;
    margin-bottom: 25px;
  }
}

.rulesLink {
  color: #ffffff;
  transition: opacity 0.2s ease-in-out;

  &:hover,
  &:active {
    color: #ffffff;
    opacity: 0.9;
  }
}