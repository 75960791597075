html {
  scroll-behavior: smooth;
}

::selection {
  background-color: #be773c7a;
}

body {
  margin: 0 !important;
  background-color: #f8f7f6;

}

.App {
  font-size: 16px;
  overflow-x: hidden;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

.menu-visible {
  width: 100%;
  position: fixed;
  overflow-y: scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

section {
  display: flex;
  justify-content: center;
  padding-right: 50px !important;
  padding-left: 50px !important;

  @media only screen and (max-width: 1108px) {
    padding-right: 65px !important;
    padding-left: 65px !important;
  }

  @media only screen and (max-width: 765px) {
    padding-right: 7vw !important;
    padding-left: 7vw !important;
  }
}

.section_wrapper {
  width: 100%;
  max-width: 1108px;
}

.logo {
  flex-shrink: 0;
  margin-right: 74px;
}

.section-title {
  font-weight: bold;
  font-size: 35px;
  text-align: center;
  text-transform: uppercase;

  @media only screen and (max-width: 1108px) {
    font-size: 25px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 20px;
  }
}

.text-accent {
  color: #be763c;
}

.Button {
  border: none;
  border-radius: 0;
  background-color: #464f3b;
  color: #ffffff;
  padding: 14px 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background-color: #343e29;
    color: #ffffff;
  }

  &:hover:disabled {
    background-color: #F5F5F5;
  }

  &:active {
    opacity: 0.7;
  }

  &::after {
    content: none;
  }

  &--light {
    background-color: #be763c;

    &:hover {
      background-color: #ac662d;
    }
  }
}

.text-accent-bold {
  font-weight: 700;
}

.text-accent-uppercase {
  text-transform: uppercase;
}

// Swiper
.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 36px !important;
}

.swiper-pagination {
  bottom: -7px !important;

  @media only screen and (max-width: 1108px) {
    bottom: 20px !important;
  }
}

.swiper-pagination-bullet {
  width: 24px !important;
  height: 2px !important;
  background-color: #464f3b !important;
  border-radius: 0 !important;

  @media only screen and (max-width: 765px) {
    width: 8px !important;
  }
}

.swiper-pagination-bullet-active {
  @media only screen and (max-width: 765px) {
    width: 12px !important;
  }
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto !important;
}

.swiper-slide.lazy-load-image-background {
  display: block;
  width: 100%;
  height: 86%;
  object-fit: cover;
  margin-bottom: 15px;

  @media only screen and (max-width: 1108px) {
    margin: 0;
  }
}

.swiper-slide-next,
.swiper-slide-prev {
  opacity: 0.5;
}

.swiper-slide {
  width: 40% !important;
  overflow: hidden;

  @media only screen and (max-width: 1108px) {
    width: 76% !important;
  }

  @media only screen and (max-width: 765px) {
    width: 100% !important;
  }

  & img {
    height: 495px;
    width: 100%;
    object-fit: cover;

    @media only screen and (max-width: 1108px) {
      height: 420px;
    }
  }

  & span {
    display: none;
  }
}

.swiper-slide-active {
  & span {
    display: block;
  }
}

.prev,
.next {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 40%;
  transform: translateY(-50%) rotate(180deg);
  left: 40px;
  z-index: 100;

  &:hover {
    opacity: 0.95;
  }

  & img {
    height: 40%;
    width: 40%;
    object-fit: contain;
  }

  @media only screen and (max-width: 1108px) {
    width: 32px;
    height: 32px;
    left: 10px;
  }

  @media only screen and (max-width: 765px) {
    display: none;
  }
}

.next {
  transform: translateY(-50%) rotate(0deg);
  right: 40px;
  left: unset;

  @media only screen and (max-width: 1108px) {
    right: 10px;
  }
}

.anticon-arrow-up {
  & svg {
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4));
  }

  &:hover {
    transform: translateY(-2px);
    transition: 0.3s;

    & svg {
      filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.6));
    }
  }
}

.map-wrapper {
  height: 644px;

  @media only screen and (max-width: 1108px) {
    height: 420px;
  }

  @media only screen and (max-width: 765px) {
    height: 220px;
  }
}

.ant-float-btn {
  bottom: 70px;
  right: 103px;

  @media only screen and (max-width: 765px) {
    right: 63px;
  }

  @media only screen and (max-width: 480px) {
    right: 23px;
  }
}

.phoneLink {
  height: 46px;
  width: 46px;
  overflow: hidden;
  position: fixed;
  bottom: 130px;
  right: 100px;
  z-index: 10;

  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));

  @media only screen and (max-width: 1108px) {
    display: block;
    z-index: 10;
  }

  @media only screen and (max-width: 765px) {
    right: 60px;
  }

  @media only screen and (max-width: 480px) {
    right: 20px;
  }

  &:hover {
    animation: shake 0.7s;
    animation-iteration-count: infinite;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.lazy-load-image-background {
  height: 100%;
  width: 100%;
}

.divider {
  height: 40px;
  width: calc(100% + 30px);
  display: none;
  background-color: #ffffff;
  flex-shrink: 0;
  margin-bottom: 25px;

  @media only screen and (max-width: 765px) {}
}

.lazy-load-image-background {
  & span {
    display: block !important;
  }
}

.ant-modal-content {
  border-radius: 0 !important;
  padding: 0 !important;
}

.ant-select {
  &-focused {
    border: 1px solid #be763c !important;
  }
}

.ant-picker {
  border-radius: 0;

  &-focused {
    box-shadow: none;
  }

  &-cell-inner::before {
    border-color: #be763c !important;
  }

  &-today-btn {
    color: #be763c !important;
  }

  &-month-btn:hover {
    color: #be763c !important;
  }

  &-year-btn:hover {
    color: #be763c !important;
  }

  &-decade-btn:hover {
    color: #be763c !important;
  }

  &-cell-selected>.ant-picker-cell-inner {
    background: #be763c !important;
  }
}

.ant-select-selector {
  padding: 0 !important;
  padding-left: 11px !important;
  border: none !important;
  box-shadow: none !important;
}

.ant-select-item-option-selected {
  background-color: #be763c70 !important;
}

.ant-btn:hover {
  color: #fff !important;
  border-color: transparent !important;
}

.ant-select-selection-item {
  @media only screen and (max-width: 765px) {
    font-size: 12px;
  }
}

.ant-select-selection-search-input {
  padding-top: 15px !important;
}

.rw-widget-picker {
  min-height: 45px !important;
  border-radius: 0 !important;
  transition: border-color 0.2s ease-in-out !important;
  border-color: #e0e0e0 !important;

  &:hover {
    border-color: #be763c !important;
  }

  &__analytics {
    width: 130px !important;
  }

}

.rw-widget-picker__analytics>.rw-widget-picker {
  min-height: 30px !important;
}


.datepicker__error.rw-widget-picker {
  min-height: 45px !important;
  border-radius: 0 !important;
  transition: border-color 0.2s ease-in-out !important;
  border-color: #e0e0e0 !important;

  &:hover {
    border-color: #be763c !important;
  }
}

.rw-state-focus .rw-widget-picker {
  box-shadow: none !important;
  outline: none !important;

  border-color: #be763c !important;
}

.rw-state-hover .rw-widget-picker {
  box-shadow: none !important;
  outline: none !important;

  border-color: #be763c !important;
}


.datepicker__error.rw-date-picker .rw-widget-picker {
  border-color: #f74646 !important;
}

.rw-calendar-popup {
  right: -1em !important;
  left: auto !important;
}

.rw-cell.rw-state-selected {
  background-color: #be763c !important;
  border-color: #be763c !important;
}

.rw-widget-input {
  font-size: 14px !important;

  @media screen and (max-width: 765px) {
    font-size: 12px !important;
  }
}

.rw-input-addon,
.rw-input-addon.rw-picker-btn {
  border-color: #e0e0e0 !important;
}

.ant-menu-dark {
  background-color: #303030 !important;
}

.ant-menu-item-selected {
  background-color: #be763c !important;
}

.ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: rgba($color: #be763c, $alpha: .5) !important;
}