.modal_container {
  padding: 43px 10px 60px 23px;
}

.modal_wrap {
  background-color: rgba(48, 48, 48, 0.9);
  border-radius: 0;
  padding: 10px;
  p {
    margin: 10px 0 35px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.modal_header {
  font-size: 16px;
  font-weight: 700;
}

.modal_header-star {
  background-image: url(./images/star.svg);
  width: 15px;
  height: 15px;
}
