.balance {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 39px 0 79px;

  @media only screen and (max-width: 1108px) {
    margin: 0 auto;
    max-width: 765px;
  }

  @media only screen and (max-width: 765px) {
    padding: 22px 0 39px;
  }
}

.title {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  margin: 18px 0;
  display: block;

  @media screen and (max-width: 1108px) {
    grid-column: 1;
    margin: 0;
  }

  @media screen and (max-width: 765px) {
    margin: 10px 0;
  }

  &_header {
    font-size: 20px;
    grid-row: 1;
    grid-column: 1;
    margin: 0;

    &:last-of-type {
      grid-column: 2;
    }
  }

  @media only screen and (max-width: 1108px) {
    font-size: 16px;
  }
}

.text {
  margin: 0 0 10px 0;
  font-size: 16px;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 10px;
  column-gap: 30px;

  @media screen and (max-width: 1108px) {
    display: flex;
    flex-direction: column;
  }
}

%container-style {
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(48, 48, 48, 0.1);
  padding: 30px 20px 30px 20px;
  max-height: 360px;
  box-sizing: border-box;
}

.replenishment {
  @extend %container-style;

  grid-column: 1;

  @media screen and (max-width: 1108px) {
    display: grid;
    grid-template-rows: 1fr 2fr 2fr;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  @media screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.details {
  position: relative;
  @extend %container-style;
  grid-column: 2;
  overflow: hidden;
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  border-radius: 0;
  cursor: pointer;
  height: 50px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #c0c0c0;
  width: calc(100% - 110px);
  padding: 10px 20px;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #be763c;
  }

  &:hover {
    border-color: #be763c;
  }

  @media only screen and (max-width: 1108px) {
    width: calc(100% - 100px);
  }
}

.input_container {
  display: flex;
}

.button {
  width: 110px;
  height: 50px;
  background-color: #464f3b;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background-color: #343e29;
  }

  @media only screen and (max-width: 1108px) {
    width: 100px;
  }
}

.table_wrapper {
  overflow: scroll;
  max-width: 100%;
  max-height: 253px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 6px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;
  min-width: 500px;
  table-layout: fixed;

  font-size: 14px;
  // text-align: center;

  @media only screen and (max-width: 1108px) {
    display: table;
  }

  @media only screen and (max-width: 765px) {
    padding: 16px 21px;
  }

  & th,
  & td {
    min-height: 50px;
    text-align: start;
    white-space: normal;
    min-width: fit-content;
    padding: 10px 20px;
    width: fit-content;

    &:first-child {
      // width: 20%;
      min-width: 20px;
      text-align: center;
    }

    &:nth-child(2) {
      text-align: center;
      min-width: 20px;
    }

    &:nth-child(3) {
      min-width: 50px;
    }

    &:nth-child(4) {
      min-width: 20px;
    }
  }

  & td {
    white-space: normal;
    word-wrap: break-word;
    line-height: 130%;
  }

  & thead tr {
    position: relative;
  }

  & tbody tr {
    position: relative;
    max-height: 70px;
    height: 100%;
    border-bottom: 0.5px solid rgba(0, 0, 0, .5);
  }

  & thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #e0e0e0;
    width: 100%;
  }

  & tbody {
    overflow: auto;
    width: 100%;
    max-height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;

    & tr>td {
      border: none;
      background-color: white;
    }

    @media only screen and (max-width: 1108px) {
      display: table-row-group;
    }
  }
}

.spinner {
  width: 100%;
  margin: 50px auto;
}

.table-spinner-container {
  top: 50%;
  z-index: 1;
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(48, 48, 48, .1);
  border-radius: 5px;
  transform: translate(-50%, -50%);
}

.alert {
  font-size: 14px;
  color: #f74646;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.alert-container {
  height: 24px;

  @media only screen and (max-width: 768px) {
    height: 17px;
  }
}