.sidebar {
  display: block;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #303030;
  z-index: 5;
  transition: left .3s ease-in-out;
  display: flex;
  flex-direction: column;

  &__collapsed {
    left: -81px
  }
}

.block {
  background-color: #000;
  width: 100vw;
  height: 50vh;
}

.menu {
  margin-top: 10px;
}

.hamburger {
  margin: 12px auto 0 auto;
  background-color: #be763c;
  box-shadow: none !important;

  &:hover {
    background-color: #ac662d !important;
  }

  &:active {
    background-color: rgba($color: #ac662d, $alpha: .7) !important
  }
}