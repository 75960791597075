.hero {
  max-height: 526px;
  height: 100%;

  @media only screen and (max-width: 1108px) {
    height: 100vh;
    background-image: linear-gradient(rgba(29, 29, 27, 0.5),
        rgba(29, 29, 27, 0.5)),
      url('../../images/main.jpg');
    background-size: cover;
    background-position-y: center;
  }

  @media only screen and (max-width: 765px) {
    padding-bottom: 113px;
  }
}

.heroContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 33px;

  @media only screen and (max-width: 1108px) {
    grid-gap: 20px;
  }

  @media only screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
    height: calc(100% - 114px);
    align-items: center;
  }
}

.discoverer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 38px 0;
  justify-content: center;

  @media only screen and (max-width: 1108px) {
    display: none;
  }
}

.discovererTablet {
  flex-direction: column;
  align-items: flex-start;
  display: none;

  @media only screen and (max-width: 1108px) {
    display: flex;
  }

  @media only screen and (max-width: 765px) {
    margin-bottom: 8px;
    align-items: center;
    text-align: left;
  }
}

.title {
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
  line-height: 1;
  margin: 0 0 6px 0;
  width: 100%;
}

.logo {
  width: 395px;
  height: 213px;
  margin-bottom: 26px;
}

.discoverer__title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 35px;
  padding: 12px 42px 12px 46px;
  background-color: #1d1d1b;
  color: white;
  margin: 0 0 26px 0;
  letter-spacing: 6.5px;
  line-height: 45.67px;
}

.discoverer__subtitle-wrapper {
  display: flex;
  margin-bottom: 10px;
  max-width: 460px;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;

  @media only screen and (max-width: 765px) {
    margin-bottom: 28px;
  }
}

.discoverer__subtitle {
  margin: 0;
  position: relative;
  text-align: start;

  &:first-child {
    margin: 0 65px 0 0;

    &::after {
      position: absolute;
      content: '';
      height: 1px;
      width: 32px;
      background-color: black;
      top: 14px;
      right: -12px;
      transform: translateX(100%);
    }
  }

  @media only screen and (max-width: 1108px) {
    color: white;
    font-size: 16px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 12px;
    line-height: 150%;
    max-width: 550px;
  }
}

.buttons {
  display: none;

  @media only screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.hero-cabinet {
  position: relative;
  background-image: url('../../images/main.jpg');
  background-size: cover;
  background-position-y: bottom;
  max-height: 99.5%;

  @media only screen and (max-width: 1108px) {
    background: none;
    margin: 0 auto;
    padding-top: 6px;
  }
}

.cabinet {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 43%;
  width: 335px;
  height: 222px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 23px 48px 20px 48px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  box-sizing: border-box;

  @media only screen and (max-width: 1108px) {
    width: 307px;
    height: 207px;
    padding: 19px 20px 20px;
    position: relative;
    top: unset;
    left: unset;
    transform: none;
    font-size: 16px;
  }

  @media only screen and (max-width: 765px) {
    display: none;
  }
}

.cabinet__title {
  font-size: 18px;
  line-height: 24.3px;
  font-weight: 400;
}

.cabinet__button {
  padding: 16px 32px;
  line-height: 20.88px;
  font-weight: 400;
  font-size: 16px;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05)
  }
}

.text-link {
  display: flex;
  background-color: transparent;
  border: none;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #be763c;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover,
  &:active {
    opacity: 0.7;
    color: #be763c;
    transform: scale(1.05)
  }
}

.whiteBtn {
  background-color: white;
  color: #303030;
  width: 155px;
  margin-bottom: 20px;
}

.borderedBtn {
  border: 2px solid white;
  color: white;
  background-color: transparent;
  width: 155px;
}

.loginIcon {
  margin-right: 10px;
}