%hover-border {
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #be763c;
  }

  &:hover {
    border-color: #be763c;
  }
}

.promo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 20px;
  font-weight: 700;
  align-self: flex-start;
  margin: 0;
}

.table {
  &_container {
    background-color: #fff;
    width: 100%;
    margin-top: 15px;
    padding: 20px 20px;
    box-sizing: border-box;
    box-shadow: 0 0 6px rgba(48, 48, 48, .1);
    border-radius: 5px;
    position: relative;
  }

  &_header {
    padding: 0 0 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_wrapper {
    overflow: scroll;
    max-width: 100%;
    max-height: 70vh;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 6px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      border: 2px solid #ffffff;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }
  }

  &_arrow {
    font-size: 12px;

    &__unactive {
      opacity: .5;
    }
  }

  &_search {

    display: flex;
    align-items: center;

    &-input {
      margin-left: 10px;
      border: 1px solid #e0e0e0;
      @extend %hover-border;

    }
  }

  &_page-size {
    align-self: center;
    height: 26px;
    width: 100px;
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
  }

  &_options-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }


  &_main {
    border-collapse: collapse;
    width: 100%;
    min-width: 1200px;
    table-layout: fixed;

    font-size: 14px;
    text-align: center;

    @media only screen and (max-width: 1108px) {
      display: table;
    }

    @media only screen and (max-width: 765px) {
      padding: 16px 21px;
    }

    & tr {
      border-bottom: .5px solid rgba(0, 0, 0, 0.5);
    }

    & th,
    & td {
      line-height: 130%;
      text-align: center;
      white-space: normal;
      min-width: 90px;
      max-width: 112px;
      overflow: hidden;
      padding: 10px 0;

      &:first-child {
        width: 5%;
        min-width: 15px;
      }

      &:nth-child(2) {
        width: 10%;
        min-width: 20px;
      }

      &:nth-child(3) {
        width: 10%;
        min-width: 20px;
      }

      &:nth-child(4) {
        width: 10%;
        min-width: 20px;
      }

      &:nth-child(5) {
        width: 5%;
        min-width: 150px;
      }

      &:nth-child(6) {
        width: 10%;
        min-width: 150px;
      }

      &:nth-child(7) {
        width: 15%;
        min-width: 150px;
      }

      &:nth-child(8) {
        width: 15%;
        min-width: 150px;
      }

      &:nth-child(9) {
        width: 10%;
        min-width: 150px;
      }

      &:nth-child(10) {
        width: 10%;
        min-width: 150px;
      }

    }

    & thead tr {
      position: relative;
    }

    & tbody tr {
      position: relative;
      min-height: 30px;
    }

    & thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #e0e0e0;
      width: 100%;
    }

    & tbody {
      overflow: auto;
      width: 100%;
      max-height: 350px;
      overflow-y: scroll;
      overflow-x: hidden;

      & tr>td {
        border: none;
        background-color: white;
      }

      @media only screen and (max-width: 1108px) {
        display: table-row-group;
      }
    }
  }
}

.is-in {
  &.in-true {
    color: #6ea729;
  }

  &.in-false {
    color: #e3342f;
  }
}

.qr-button {
  height: 25px;
  font-size: 16px;
  width: 25px;
  background-position: center;
  padding: 0;
  border-radius: 5px 0 0 5px;
  border: 1px solid #6cb2eb;
  background-color: #6cb2eb;
  cursor: pointer;
}

.edit {
  height: 25px;
  font-size: 16px;
  width: 25px;
  background-position: center;
  padding: 0;
  border: 1px solid #6c757d;
  background-color: #6c757d;
  cursor: pointer;
}

.delete {
  height: 25px;
  font-size: 16px;
  width: 25px;
  background-position: center;
  border-radius: 0 5px 5px 0;
  padding: 0;
  border: 1px solid #e3342f;
  background-color: #e3342f;
  cursor: pointer;
}

.coworking_cell {
  display: flex;
  justify-content: space-between;
}

.add-button {
  padding: 6px 12px;
  background-color: #24cb15;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.pagination {

  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }

  &_text {
    color: #000;
    font-size: 16px;
    align-self: center;

    @media only screen and (max-width: 1108px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  &_input {
    box-sizing: border-box;
    align-self: center;
    margin-left: 10px;
    height: 26px;
    border: 1px solid #e0e0e0;
    width: 40px;
  }

  &_goto {
    display: flex;
    justify-content: center;
  }

  &_button-container {
    display: flex;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }

  &_button {
    font-size: 16px;
    height: 40px;
    justify-self: flex-end;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    color: #000;
    padding: 0 12px;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;

    &:hover {
      background-color: rgba($color: #be763c, $alpha: .5);
      color: #fff
    }

    &:active {
      background-color: #be763c
    }

    &:disabled {
      background-color: #e0e0e0;
      color: #fff
    }

    &__first {
      border-radius: 5px 0 0 5px
    }

    &__last {
      border-radius: 0 5px 5px 0;
    }

    &__middle {
      border-radius: 0;
    }

    @media only screen and (max-width: 1108px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 12px;
      height: 25px;
      padding: 0 10px;
    }
  }
}

.table-spinner-container {
  top: 50%;
  z-index: 1;
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(48, 48, 48, .1);
  border-radius: 5px;
  transform: translate(-50%, -50%);
}