.users {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 20px;
  font-weight: 700;
  align-self: flex-start;
  margin: 0;
}

.table {
  &_container {
    background-color: #fff;
    width: 100%;
    margin-top: 15px;
    padding: 20px 20px;
    box-sizing: border-box;
    box-shadow: 0 0 6px rgba(48, 48, 48, .1);
    border-radius: 5px;
    position: relative;
  }

  &_wrapper {
    overflow: scroll;
    max-width: 100%;
    max-height: 70vh;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 6px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      border: 2px solid #ffffff;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }
  }

  &_main {
    border-collapse: collapse;
    width: 100%;
    min-width: 1000px;
    table-layout: fixed;

    font-size: 14px;
    text-align: center;

    @media only screen and (max-width: 1108px) {
      display: table;
    }

    @media only screen and (max-width: 765px) {
      padding: 16px 21px;
    }

    & tr {
      border-bottom: .5px solid rgba(0, 0, 0, 0.5);
    }

    & th,
    & td {
      text-align: center;
      white-space: normal;
      min-width: 90px;
      max-width: 112px;
      overflow: hidden;
      padding: 10px 0;

      &:first-child {
        width: 5%;
        min-width: 15px;
      }

      &:nth-child(2) {
        width: 15%;
        min-width: 20px;
      }

      &:nth-child(3) {
        width: 14%;
        min-width: 20px;
      }

      &:nth-child(4) {
        width: 14%;
        min-width: 20px;
      }

      &:nth-child(5) {
        width: 14%;
        min-width: 150px;
      }

      &:nth-child(6) {
        width: 14%;
        min-width: 150px;
      }

      &:nth-child(7) {
        width: 14%;
        min-width: 150px;
      }

      &:nth-child(8) {
        width: 10%;
        min-width: 150px;
      }

    }

    & thead tr {
      position: relative;
    }

    & tbody tr {
      position: relative;
      min-height: 30px;
    }

    & thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #e0e0e0;
      width: 100%;
    }

    & tbody {
      overflow: auto;
      width: 100%;
      max-height: 350px;
      overflow-y: scroll;
      overflow-x: hidden;

      & tr>td {
        border: none;
        background-color: white;
      }

      @media only screen and (max-width: 1108px) {
        display: table-row-group;
      }
    }
  }
}

.is-in {
  &.in-true {
    color: #6ea729;
  }

  &.in-false {
    color: #e3342f;
  }
}

.edit {
  height: 25px;
  font-size: 16px;
  width: 25px;
  background-position: center;
  padding: 0;
  border-radius: 5px;
  border: 1px solid #6c757d;
  background-color: #6c757d;
  cursor: pointer;
}

.coworking_cell {
  display: flex;
  justify-content: space-between;
}

.table-spinner-container {
  top: 50%;
  z-index: 1;
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(48, 48, 48, .1);
  border-radius: 5px;
  transform: translate(-50%, -50%);
}