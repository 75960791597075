.container {
  display: flex;
  margin: auto;
  align-items: center;
  flex-direction: column;
  padding: 246px 0 0 0;
  @media screen and (max-width: 765px) {
    padding: 150px 0 222px 0;
  }
}

.number {
  font-size: 140px;
  line-height: 169px;
  font-weight: 700;

  @media screen and (max-width: 765px) {
    font-size: 80px;
    line-height: 97px;
  }
}

.text {
  margin: 5px 0 0 0;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;

  @media screen and (max-width: 765px) {
    font-size: 12px;
    line-height: 15px;
  }
}

.link {
  color: #be763c;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  transition: opacity 0.3s ease-in-out;
  margin: 50px 0 0 0;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: 765px) {
    font-size: 12px;
    line-height: 15px;
    margin: 20px 0 0 0;
  }
}
