%transition {
  transition: color 0.2s ease-in-out;
}

%link-transition {
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.85;
  }
}

.topMenu {
  height: 100vh;
  width: 219px;
  box-sizing: border-box;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(0);
  transition: transform .3s ease-in-out;
  justify-content: flex-end;
  background-color: #1d1d1b50;
  z-index: 10;
  overflow: scroll;

  @media only screen and (max-width: 765px) {
    background-color: transparent;
    width: 100%;
  }
}

.topMenuInvisible {
  transform: translateX(219px);

  @media only screen and (max-width: 765px) {
    transform: translateX(100%);
  }
}

.logo {
  display: none;

  @media only screen and (max-width: 765px) {
    width: 123px;
    display: block;
    fill: #ffffff;
    height: 67px;
    margin-bottom: 46px;
  }
}

.menuContent {
  z-index: 2;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
  width: 219px;
  padding: 50px 0px 80px 36px;
  background-color: #1d1d1b;
  color: #ffffff;
  min-height: 650px;

  @media only screen and (max-width: 765px) {
    width: 100%;
    background: rgba(48, 48, 48, 0.95);
    align-items: center;
    padding: 30px 22px 80px 22px;
    backdrop-filter: blur(5px);
  }
}

.closeIcon {
  align-self: flex-end;
  cursor: pointer;
  transition: 0.3s;
  margin: 0px 25px 14px 0;

  &:hover {
    transform: scale(0.9);
  }

  &:active {
    transform: scale(1.1);
  }

  font-size: 20px;
}

.menu {
  @media only screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 22px;
  }
}

.menu-item {
  margin-bottom: 21px;

  &:last-child {
    margin: 0;
  }
}

.menu-link,
.contact-link,
.policy {
  @extend %transition;
  color: #ffffff;

  &:hover {
    color: #be763c;
  }

  &:active {
    color: darken($color: #be763c, $amount: 10);
  }
}

.contact-link {
  margin-top: auto;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 765px) {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.socialLink {
  margin-right: 8px;
  @extend %link-transition;

  &:last-child {
    margin: 0;
  }
}

.socialPic {
  height: 24px;
  width: 24px;
}

.socials {
  display: none;

  @media only screen and (max-width: 765px) {
    display: flex;
  }
}

.policy {
  display: none;

  @media only screen and (max-width: 765px) {
    display: block;
  }
}

.phoneIcon {
  margin-right: 10px;
  display: none;

  @media only screen and (max-width: 765px) {
    display: block;
  }
}

.phoneWhiteIcon {
  margin-right: 10px;
  display: none;

  @media only screen and (max-width: 1108px) {
    display: block;
  }

  @media only screen and (max-width: 765px) {
    display: block;
    display: none;
  }
}