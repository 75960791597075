.container {
  position: relative;
  color: #303030;
  display: flex;
  flex-direction: column;
  background: #f8f7f6;
}

.title {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 12px;
  text-transform: uppercase;
  padding: 0 15px;
  text-align: center;
  display: none;

  @media only screen and (max-width: 1108px) {
    display: block;
    font-size: 25px;
    margin-bottom: 25px;
  }
}

.contacts {
  padding: 36px 54px;
  position: absolute;
  background: linear-gradient(135deg,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0));
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  right: 134px;
  top: 50%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);

  @media only screen and (max-width: 1108px) {
    padding: 37px 32px;
    right: auto;
    left: 41px;
    top: 57%;
  }

  @media only screen and (max-width: 765px) {
    order: 1;
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    transform: none;
    padding: 25px 15px 49px;
  }
}

.contacts__title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 35px;
  margin-bottom: 31px;

  @media only screen and (max-width: 1108px) {
    display: none;
  }
}

.contacts__title--tiny {
  display: none;

  @media only screen and (max-width: 1108px) {
    display: block;
    font-weight: 700;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 20px;
  }
}

.contacts__list {
  display: flex;
  flex-direction: column;
}

.contacts__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.contacts__item-title {
  font-weight: 700;
  font-size: 18px;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

.contacts__item-subtitle {
  font-weight: 400;
  font-size: 16px;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

.contacts__link {
  display: block;
  color: inherit;
  cursor: pointer;
  transition: color .2s ease-in-out, transform .2s ease-in-out;


  &:hover {
    color: lighten(#303030, 20%);
    transform: scale(1.01);
  }
}

.socialLink {
  margin-right: 18px;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;

  &:hover {
    opacity: 0.7;
    transform: scale(1.05);
  }
}

.socialPic {
  height: 36px;
  width: 36px;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
    height: 28px;
    width: 28px;
  }
}

.socials {
  display: flex;
}