.guest {
  display: grid;
  grid-template-columns: 1fr 2.1fr;
  gap: 30px;
  margin: 0 auto;
  padding: 40px 0 65px;
  @media only screen and (max-width: 1108px) {
    padding: 39px 0 31px;
    gap: 13px;
    align-items: center;
    max-width: 635px;
  }
  @media only screen and (max-width: 765px) {
    grid-template-columns: 1fr;
    padding: 22px 0 45px;
    width: 100%;
    justify-content: center;
    max-width: 380px;
  }
}

// Layout
.one {
  grid-column: 1 / 3;
  grid-row: 1;
  @media only screen and (max-width: 765px) {
    max-width: calc(100vw - 30px);
    grid-column: 1;
    grid-row: 1;
  }
}

.two {
  grid-column: 1;
  grid-row: 2 / 4;
  @media only screen and (max-width: 1108px) {
    grid-column: 1/3;
    grid-row: 2;
  }
  @media only screen and (max-width: 765px) {
    grid-column: 1;
    grid-row: 2;
  }
}

.three {
  grid-column: 2;
  grid-row: 2 / 4;
  @media only screen and (max-width: 1108px) {
    grid-column: 1/3;
    grid-row: 3;
  }
  @media only screen and (max-width: 765px) {
    grid-column: 1;
    grid-row: 3;
  }
}

.section_header {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  @media only screen and (max-width: 1108px) {
    font-size: 16px;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 765px) {
    font-size: clamp(16px, 5vw, 20px);
  }
}

.section_description {
  font-size: 14px;
  @media only screen and (max-width: 765px) {
    font-size: 12px;
  }
}

.section_main {
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(48, 48, 48, 0.1);
  @media only screen and (max-width: 1108px) {
    max-width: 635px;
  }
  @media only screen and (max-width: 765px) {
    max-width: calc(100vw - 30px);
  }
}

.section_addition {
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(48, 48, 48, 0.1);
  @media only screen and (max-width: 1108px) {
    max-width: 635px;
  }
  @media only screen and (max-width: 765px) {
    max-width: calc(100vw - 30px);
  }
}
