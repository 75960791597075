.form {
  padding: 24px 29px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1108px) {
    padding: 15px 15px 20px;
  }
}

.spinner {
  margin: 200px auto;
}