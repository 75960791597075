.rent-benefits-section {
  background-color: #1d1d1b;
  padding: 77px 0 94px;
  color: #ffffff;

  @media only screen and (max-width: 1108px) {
    padding: 47px 0 20px;
  }

  @media only screen and (max-width: 765px) {
    padding: 33px 0 40px;
  }
}

.rent-benefits-section__title {
  font-size: 35px;
  font-weight: 700;
  line-height: 130.5%;
  text-transform: uppercase;
  text-align: start;
  margin: 0 0 54px 0;

  @media only screen and (max-width: 1108px) {
    font-size: 25px;
    text-align: center;
    margin-bottom: 13px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 20px;
    white-space: nowrap;
    margin-bottom: 27px;
  }
}

.benefits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 40px;
  grid-auto-flow: column;
  grid-row-gap: 60px;
  margin-bottom: 10px;
  justify-items: flex-start;

  @media only screen and (max-width: 1108px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-row-gap: 12px;
    grid-column-gap: 3%;
    grid-auto-flow: row;
  }

  @media only screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
    grid-row-gap: 32px;
  }
}

.benefits-item {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.benefits-item--text {
  @media only screen and (max-width: 1108px) {
    grid-column: 1/3;
    grid-row: 8;
    width: auto;
    justify-self: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
  }

  @media only screen and (max-width: 1108px) {
    margin-top: 18px;
  }
}

.benefits-item__icon {
  display: flex;
  margin-right: 32px;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 20%;

  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  @media only screen and (max-width: 1108px) {
    & img {
      height: 41px;
      width: 46px;
    }

    height: 40px;
    width: 10%;
    margin-right: 35px;
  }
}

.benefits-item__title {
  margin: 0;
  font-size: 16px;
  padding: 0 20px 0 0;
  line-height: 150%;

  @media screen and (max-width: 1108px) {
    font-size: 14px;
    padding: 0;
  }
}