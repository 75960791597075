.form {
  padding: 24px 29px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1108px) {
    padding: 15px 15px 20px;
  }
}

.form_group {
  padding-top: 10px;
  display: flex;
  width: 100%;

  &__column {
    flex-direction: column;
    align-items: center;
    & > button,
    > p {
      align-self: center;
    }
  }
  & label {
    padding-bottom: 10px;
    font-weight: 400;
    width: 100%;
    text-align: left;

    @media only screen and (max-width: 768px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

.form_group-link {
  cursor: pointer;
  font-size: 16px;
  padding-top: 10px;
  color: #464f3b;
  margin: 0;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.form_group-field,
.form_control {
  font-size: 14px;
}

.form_control {
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  height: 50px;
  width: 100%;
  padding-left: 11px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  &:focus {
    border-color: #be763c;
  }
  &:hover {
    border-color: #be763c;
  }
  &::placeholder {
    color: #e0e0e0;
  }
  @media only screen and (max-width: 768px) {
    max-height: 45px;
  }
}

.submit_button {
  margin-top: 20px;
  width: 210px;
  height: 50px;
  background-color: #464f3b;
  color: #fff;
  border: none;
  white-space: normal;
  &:hover {
    background-color: #343e29 !important;
  }
}

.alert {
  font-size: 14px;
  color: #f74646;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.alert-container {
  height: 24px;
  @media only screen and (max-width: 768px) {
    height: 17px;
  }
}
