.topMenu {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  justify-content: flex-end;
  background-color: #1d1d1b50;
  z-index: 2;
  overflow: scroll;
  transform: translateX(0);
  transition: transform .3s ease-in-out;

  @media only screen and (max-width: 765px) {
    background-color: transparent;
  }
}

.topMenuInvisible {
  transform: translateX(100%);
}

.profile {
  display: none;

  @media only screen and (max-width: 765px) {
    width: 123px;
    display: block;
    fill: #ffffff;
    height: 67px;
    margin: 58px 0 25px;
  }
}

.menuContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 300px;
  padding: 50px 69px 58px 50px;
  background-color: #1d1d1b;
  color: #ffffff;
  min-height: 500px;

  @media only screen and (max-width: 765px) {
    width: 100%;
    background: rgba(48, 48, 48, 0.95);
    align-items: center;
    padding: 30px 22px 22px 22px;
    backdrop-filter: blur(5px);
  }
}

.closeIcon {
  align-self: flex-end;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 14px;

  &:hover {
    transform: scale(0.9);
  }

  &:active {
    transform: scale(1.1);
  }

  font-size: 20px;
}

.menu {
  list-style-type: none;
  padding: 0;

  @media only screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 22px;
  }
}

.menu-item {
  margin-bottom: 21px;

  &:last-child {
    margin: 0;
  }
}

.menu-link {
  color: #ffffff;
  cursor: pointer;

  &:hover {
    color: #be763c;
  }

  &:active {
    color: darken($color: #be763c, $amount: 10);
  }
}