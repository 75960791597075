.forms_wrapper {
  height: 100%;
  overflow: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 765px) {
    z-index: 1;
  }
}

.forms_section {
  margin: auto;
  position: relative;
  min-width: 390px;
  grid-row: 2;

  @media only screen and (max-width: 1108px) {
    min-width: 307px;
  }

  @media only screen and (max-width: 765px) {
    min-width: 290px;
    height: unset;
  }
}

.section_header {
  font-size: 25px;
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
  min-width: 390px;

  @media only screen and (max-width: 1108px) {
    font-size: 16px;
    min-width: 307px;
  }

  @media only screen and (max-width: 765px) {
    color: #ffffff;
    line-height: 130%;
  }
}

.section_header-name {
  color: #be763c;
  display: block;
  margin-top: 10px;

  @media only screen and (max-width: 765px) {
    color: #ffffff;
    margin-top: 2px;
  }
}

.section_form {
  margin-top: 25px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(48, 48, 48, 0.1);
  max-width: 390px;

  @media only screen and (max-width: 1108px) {
    max-width: 307px;
  }

  @media only screen and (max-width: 765px) {
    margin-top: 20px;
  }
}

.section_button-group {
  display: flex;
  border-bottom: 1px solid #464f3b;
  font-size: 16px;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

.section_button {
  width: 50%;
  height: 64px;
  background: none;
  color: inherit;
  border: none;

  &.active {
    background-color: #464f3b;
    box-shadow: 0px 3px 5px rgba(31, 35, 26, 0.2);
    color: #fff;
  }

  @media only screen and (max-width: 1108px) {
    height: 54px;
  }
}