// benefit

.benefits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 28px;
  row-gap: 49px;
  @media only screen and (max-width: 1108px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 37px;
  }
  @media only screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
    row-gap: 41px;
  }
}

.benefit {
  padding: 104px 0;
  @media only screen and (max-width: 1108px) {
    padding: 69px 0 84px 0;
  }
  @media only screen and (max-width: 765px) {
    padding: 25px 0;
    padding-bottom: 89px;
  }
}

.benefit-section__title {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 82px 0;
  font-size: 35px;
  line-height: 130.5%;
  @media only screen and (max-width: 1108px) {
    font-size: 25px;
    text-align: start;
    margin-bottom: 34px;
  }
  @media only screen and (max-width: 765px) {
    font-size: 20px;
    margin-bottom: 25px;
  }
}

.benefit__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.benefit__title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  @media only screen and (max-width: 1108px) {
    margin-bottom: 5px;
  }
}

.benefit__title {
  font-size: 18px;
  margin: 0 0 0 10px;
  font-weight: bold;
  @media only screen and (max-width: 1108px) {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
}

.benefit__description {
  margin: 0;
  font-size: 14px;
  text-align: start;
  line-height: 24px;
  @media only screen and (max-width: 1108px) {
    line-height: 21px;
  }
}
