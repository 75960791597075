.main {
  background-color: #f8f7f6;
  padding: 0 !important;
}

.section_wrapper {
  display: flex;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  max-width: 1440px;
  @media only screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}
