%hover-border {
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #be763c;
  }

  &:hover {
    border-color: #be763c;
  }
}

.modal .ant-modal-content {
  padding: 0;
}

.modal_wrap {
  background-color: rgba(48, 48, 48, 0.9);
}

.modal_body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 458px;
  box-sizing: border-box;

  @media screen and (max-width: 765px) {
    height: 391px;
  }
}

.side {
  width: 50%;
  height: 458px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(rgba(48, 48, 48, 0.81),
      rgba(48, 48, 48, 0.81)),
    url(./images/lkMain.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  background-color: #d9d9d9;

  @media only screen and (max-width: 765px) {
    display: none;
  }
}

.logo {
  height: 110px;

  @media only screen and (max-width: 1108px) {
    width: 240px;
  }

  @media only screen and (max-width: 765px) {
    display: none;
  }
}

.main {
  width: 50%;
  height: 458px;
  padding: 25px 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1108px) {
    padding: 32px 11px;
  }

  @media only screen and (max-width: 765px) {
    width: 290px;
    height: 391px;
    padding: 17px 19px;
  }
}

.main_header {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 11px;
  border-bottom: 1px solid #c0c0c0;
  max-width: 340px;
  width: 100%;

  @media only screen and (max-width: 1108px) {
    font-size: 16px;
    line-height: 135%;
  }

  @media only screen and (max-width: 765px) {
    font-size: 14px;
  }
}

.main_body {
  padding: 30px 0 20px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 765px) {
    padding: 27px 0 23px 0;
  }
}

.button_group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 20px;
}

.book_button {
  width: 125px;
  height: 50px;

  &:disabled {
    background-color: #c0c0c0;
    color: #fff;
  }

  @media only screen and (max-width: 1108px) {
    width: 100px;
  }
}

.topup_button {
  width: 165px;
  height: 50px;
  border: 1px solid #464f3b;
  background-color: #fff;
  color: #303030;

  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    color: #303030;
    background-color: #c0c0c0;
  }

  @media only screen and (max-width: 1108px) {
    width: 150px;
  }
}

.body_item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 16px;

    @media only screen and (max-width: 1108px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 765px) {
      font-size: 12px;
    }
  }

  &:first-child {
    padding-bottom: 20px;
    padding-right: 20px;

    @media screen and (max-width: 765px) {
      padding-bottom: 13px;
    }
  }
}

.item-title {
  display: flex;
  font-weight: 700;
  font-size: 18px;
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 12px;
  }
}

.body-icon {
  width: 31px;
  height: 31px;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: center center;
  background-color: #464f3b;

  &.balance {
    background-image: url(./images/Balance.svg);
  }

  &.membership {
    background-image: url(./images/Membership.svg);
  }
}

.body_options {
  flex-direction: column;

  .options_title {
    font-size: 14px;
    color: #c0c0c0;
    text-align: center;

    @media only screen and (max-width: 1108px) {
      font-size: 12px;
    }

    @media only screen and (max-width: 765px) {
      font-size: 10px;
    }
  }
}

.item-option_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  border: none;
  width: 350px;
  height: 136px;
  padding: 10px 0 10px 5px;
  column-gap: 10px;
  row-gap: 10px;

  @media screen and (max-width: 1108px) {
    width: 300px;
  }

  @media screen and (max-width: 765px) {
    max-height: 100px;
    max-width: 260px;
  }
}

.option {
  align-self: flex-start;
  display: none;
  min-width: 50%;
  max-width: 100%;
  padding-bottom: 10px;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
    letter-spacing: -0.5px;
  }

  @media screen and (max-width: 765px) {
    padding-bottom: 0;
  }

  &-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #303030;
    width: 100%;
    min-width: 40%;
    max-width: 45%;
    cursor: pointer;

    @media only screen and (max-width: 1108px) {
      font-size: 14px;
      letter-spacing: -0.5px;
    }

    @media screen and (max-width: 765px) {
      padding-bottom: 0;
    }
  }

}


.option-text {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding: 10px 0;
  text-align: center;
  transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out;
  border: 1px solid #c0c0c0;

  &__crossed {
    text-decoration: line-through;
  }

  &:hover {
    border: 1px solid #464f3b;
    background-color: #464f3b;
    color: #fff
  }
}

.option:checked+.option-text {
  border: 1px solid #464f3b;
  background-color: #464f3b;
  color: #fff
}

.main_footer {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #c0c0c0;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    color: #be763c;
  }

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 12px;
  }
}

.option>span:last-child {
  padding-right: 5px;
  padding-left: 5px;
}

input.form_control {
  font-size: 14px;
  padding: 0 9px 0 11px;

  @media screen and (max-width: 765px) {
    font-size: 12px;
  }
}

.form_control {
  cursor: pointer;
  border: 1px solid #e0e0e0;
  height: 45px;
  width: 90%;
  margin: 0 auto;
  @extend %hover-border;

  & div {
    align-items: center;
    height: 43px !important;
    border: none !important;
  }
}

.preloader {
  margin: auto 0;
  width: 100px;
}