.footer-section {
  background-color: #1d1d1b;
  color: #ffffff;
}

.footer {
  a {
    display: block;
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;


    &:hover {
      opacity: 0.7;
      transform: scale(1.05);
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 45px 0;

  @media only screen and (max-width: 1108px) {
    flex-direction: column;
    padding: 32px 0;
  }

  @media only screen and (max-width: 765px) {
    padding: 34px 27px;
    align-items: flex-start;
  }
}

.logo {
  fill: #ffffff;
  margin-right: 80px;

  @media only screen and (max-width: 1108px) {
    margin-right: 0;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 765px) {
    margin-bottom: 24px;
  }
}

.footer__menu {
  margin: 0;
  display: flex;
  flex-grow: 1;

  @media only screen and (max-width: 1108px) {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 9px;
  }

  @media only screen and (max-width: 765px) {
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 18px;
  }
}

.footer__menu-item {
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 765px) {
    margin-bottom: 18px;
    margin-right: 0;

    &:last-child {
      margin: 0;
    }
  }
}

.footer__menu-link {
  color: inherit;

  &:hover {
    color: #ffffff;
    opacity: 0.7;
  }
}

.policy {
  color: #ffffff;

  &:hover {
    color: #ffffff;
    opacity: 0.7;
  }

  @media only screen and (max-width: 765px) {
    order: 1;
  }
}