.actions-section {
    background-color: #1d1d1b;
    color: white;
    padding: 73px;

    &--disable {
        display: none !important;
    }

    @media only screen and (max-width: 1108px) {
        padding: 59px;
        padding-bottom: 98px;
    }

    @media only screen and (max-width: 765px) {
        padding: 29px;
        padding-bottom: 68px;
    }
}

.actions__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: -30px;

    @media only screen and (max-width: 765px) {
        flex-direction: column;
    }
}

.action-card {
    position: relative;
    width: 100%;
    max-width: 49%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #fff;
    color: #1d1d1b;
    padding: 33px 26px 26px 59px;
    margin-bottom: 30px;
    overflow: hidden;

    @media only screen and (max-width: 1108px) {
        padding: 14px 26px 15px 18px;
        height: auto;
    }

    @media only screen and (max-width: 765px) {
        max-width: 70%;
        padding: 20px 20px 15px 20px;
        margin-bottom: 18px;
    }
}

.actions-sticker {
    position: absolute;
    right: -20px;
    top: -25px;

    @media only screen and (max-width: 1108px) {
        right: -23px;
        top: -28px;
        height: 100px;
    }
}

.action-card__time-to-end {
    display: flex;
    align-items: center;
    color: #acacac;
    margin-bottom: 5px;

    @media only screen and (max-width: 1108px) {
        margin-bottom: 12px;
    }
}

.action-card__title {
    margin-left: 10px;
}

.action-days {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 17px;
}

.action-new-condition__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media only screen and (max-width: 1108px) {
        margin-bottom: 25px;
    }
}

.action-new-condition {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 1108px) {
        flex-direction: column;
    }
}

.action-prev-price {
    text-decoration: line-through;
    margin-bottom: 8px;
}

.action-new-price {
    font-weight: bold;
    font-size: 19px;
    margin-bottom: 23px;

    @media only screen and (max-width: 1108px) {
        font-size: 18px;
        margin-bottom: 10px;
    }
}

.actions__title {
    margin-bottom: 62px;

    @media only screen and (max-width: 1108px) {
        margin-bottom: 32px;
    }

    @media only screen and (max-width: 765px) {
        margin-bottom: 21px;
    }
}

.action-button {
    align-self: end;
    padding: 25px 36px;

    @media only screen and (max-width: 1108px) {
        padding: 18px 26px;
        align-self: center;
    }
}

.location-title {
    color: #be763c;
    font-size: 23px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 4px;

    &--beta-kovorking {
        color: #464f3b;
    }

    @media only screen and (max-width: 1108px) {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 10px;
    }
}

.action-old-price {
    font-weight: bold;
    font-size: 14px;
    color: #acacac;

    @media only screen and (max-width: 1108px) {
        font-size: 12px;
        font-weight: 400;
    }
}

.lightningIcon {
    @media only screen and (max-width: 1108px) {
        height: 12px;
    }
}

.link {
    @media only screen and (max-width: 1108px) {
        align-self: center;
    }
}