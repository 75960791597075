%hover-border {
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #be763c;
  }

  &:hover {
    border-color: #be763c;
  }
}

// Layout
.one {
  grid-column: 1;
  grid-row: 1;
}

.two {
  grid-column: 2 / 4;
  grid-row: 1;

  @media only screen and (max-width: 765px) {
    grid-column: 2 / 5;
  }
}

.three {
  grid-column: 1;
  grid-row: 2;

  @media only screen and (max-width: 1108px) {
    grid-column: 1;
    grid-row: 3;
  }
}

.four {
  grid-column: 2 / 4;
  grid-row: 2;

  @media only screen and (max-width: 1108px) {
    grid-column: 2 / 4;
    grid-row: 3;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 2 / 5;
    grid-row: 3;
  }
}

.five {
  grid-column: 1;
  grid-row: 3;

  @media only screen and (max-width: 1108px) {
    grid-row: 2;
  }
}

.six {
  grid-column: 2 / 4;
  grid-row: 3;

  @media only screen and (max-width: 1108px) {
    grid-column: 2 / 4;
    grid-row: 2;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 2 / 5;
  }
}

.seven {
  grid-column: 1;
  grid-row: 4;

  @media only screen and (max-width: 1108px) {
    grid-column: 4;
    grid-row: 1;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 1;
    grid-row: 4;
  }
}

.eight {
  grid-column: 2 / 4;
  grid-row: 4;

  @media only screen and (max-width: 1108px) {
    grid-column: 5;
    grid-row: 1 / 4;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 2 / 5;
    grid-row: 4;
  }
}

.nine {
  grid-column: 2;
  grid-row: 5;

  @media only screen and (max-width: 1108px) {
    grid-column: 1;
    grid-row: 4;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 3 / 5;
    grid-row: 5;
  }
}

.section_top {
  padding: 18px 25px;
  border-bottom: 1px solid #c0c0c0;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  gap: 10px;
  align-items: center;

  @media only screen and (max-width: 1108px) {
    padding: 15px 17px;
    font-size: 16px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 14px;
    padding: 13px 17px 10px;
  }
}

.top-img {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-image: url(./images/Invite.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #464f3b;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media only screen and (max-width: 1108px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  }

  @media only screen and (max-width: 765px) {
    grid-template-columns: 2fr 1fr 1fr 2fr;
    row-gap: 20px;
    column-gap: 10px;
  }
}

.form_main {
  padding: 26px 40px 22px 20px;

  @media only screen and (max-width: 1108px) {
    padding: 25px 24px 35px;
  }

  @media only screen and (max-width: 765px) {
    padding: 22px 10px 23px 17px;
  }
}

label {
  font-weight: 700;
  font-size: 14px;
  color: #303030;
  align-self: center;

  @media screen and (max-width: 765px) {
    font-size: 12px;
  }
}

input.form_control {
  font-size: 14px;
  padding: 0 9px 0 11px;

  @media screen and (max-width: 765px) {
    font-size: 12px;
  }
}

.form_control {
  cursor: pointer;
  border: 1px solid #e0e0e0;
  height: 45px;
  @extend %hover-border;

  & div {
    align-items: center;
    height: 43px !important;
    border: none !important;
  }
}

.comment {
  cursor: text;
  border-radius: 0;
  font-size: 14px;
  height: 84px !important;
  padding: 10px 11px;
  line-height: normal;
  border: 1px solid #e0e0e0;
  resize: none !important;

  &::placeholder {
    color: #e0e0e0;
  }

  @extend %hover-border;

  @media only screen and (max-width: 1108px) {
    height: 185px !important;
  }

  @media only screen and (max-width: 765px) {
    height: 100px !important;
  }
}

.button {
  width: 110px;
  height: 50px;
  font-size: 16px;
  align-self: center;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

:global(.react-datepicker__day--selected) {
  background-color: #be763c !important;
}

:global(.react-datepicker__day--keyboard-selected) {
  background-color: #be773c56 !important;
}

.alert {
  font-size: 14px;
  color: #f74646;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.alert-container {
  height: 24px;

  @media only screen and (max-width: 768px) {
    height: 17px;
  }
}