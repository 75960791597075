.section_top {
  padding: 18px 25px;
  border-bottom: 1px solid #c0c0c0;

  font-weight: 700;
  font-size: 18px;

  display: flex;
  gap: 10px;
  align-items: center;

  @media only screen and (max-width: 1108px) {
    padding: 15px 17px;
    font-size: 16px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 14px;
  }
}

.section_bottom {
  position: relative;
  overflow: hidden;
  padding: 30px 20px 30px 20px;
  max-height: 424px;
  box-sizing: border-box;
}

.active {
  width: 31px;
  height: 31px;
  border-radius: 50%;

  background-image: url(./images/Active.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #464f3b;
}

.table_wrapper {
  overflow: scroll;
  max-width: 100%;
  max-height: 364px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 6px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
}

.table {
  border-collapse: collapse;
  // position: relative;
  // width: 100%;
  min-width: 500px;
  table-layout: fixed;

  font-size: 14px;
  // text-align: center;

  @media only screen and (max-width: 1108px) {
    display: table;
  }

  @media only screen and (max-width: 765px) {
    padding: 16px 21px;
  }

  & tr {
    border-bottom: .5px solid rgba(0, 0, 0, 0.5);
  }


  & th,
  & td {
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    padding: 10px 20px;
    width: fit-content;

    &:first-child {
      min-width: fit-content;
      max-width: 100%;
      text-align: center;
    }

    &:nth-child(2) {
      min-width: 20px;
    }

    &:nth-child(3) {
      min-width: 50px;
    }

    &:nth-child(4) {
      min-width: 20px;
    }

    &:nth-child(5) {
      width: 10%;
      min-width: 20px;
      text-align: center;
    }

    &:nth-child(6) {
      min-width: 20px;
    }

    &:nth-child(7) {
      text-align: center;
      min-width: 20px;
    }
  }

  & th {
    padding: 5px 20px 5px 20px;
  }

  & td {
    white-space: normal;
    word-wrap: break-word;
    line-height: 130%;
  }

  & thead tr {
    position: relative;
  }

  & tbody tr {
    position: relative;
  }

  & thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #e0e0e0;
    width: 100%;
  }

  & tbody {
    overflow: auto;
    width: 100%;
    max-height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;

    & tr>td {
      border: none;
      background-color: white;
    }

    @media only screen and (max-width: 1108px) {
      display: table-row-group;
    }
  }
}

.status {
  width: 25px;
  height: 25px;
  margin: auto;

  background-repeat: no-repeat;
  background-position: center center;

  &.status-true {
    background-image: url(./images/validSign.svg);
  }

  &.status-false {
    background-image: url(./images/notValidSign.svg);
  }
}

.is-in {
  &.in-true {
    color: #6ea729;
  }

  &.in-false {
    color: #c0c0c0;
  }
}

.qr {
  width: 21px;
  height: 13px;
  margin: auto;

  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(./images/Eye.svg);

  cursor: pointer;
}

.spinner {
  width: 100%;
  margin: 50px auto;
  position: absolute;
}

.arrow {
  font-size: 12px;

  &_unactive {
    opacity: .5;
  }
}

.table-spinner-container {
  top: 50%;
  z-index: 1;
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(48, 48, 48, .1);
  border-radius: 5px;
  transform: translate(-50%, -50%);
}