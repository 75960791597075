.section-header {
  border-bottom: 1px solid black;

  @media only screen and (max-width: 1108px) {
    display: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 29px 0 21px;
}

.logo {
  margin-right: 52px;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
    transform: scale(1.05)
  }
}

.header__menu {
  display: flex;
  margin: 0;
  padding: 0;
}

.header__item {
  flex-shrink: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  margin-right: 35px;
  cursor: pointer;
  transition: 0.3s;
}

.header__item-link {
  color: inherit;
  display: block;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;


  &:hover {
    color: #be763c;
    transform: scale(1.05)
  }

  &:active {
    opacity: 0.7;
  }
}

.telephone {
  margin-right: 36px;
}

.headerButton {
  padding: 24px 36px;
  transition: transform 0.2s ease-in-out, background-color .2s ease-in-out;

  &:hover {
    transform: scale(1.05)
  }
}