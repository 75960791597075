.analytics {
  display: flex;
  flex-direction: column;
  align-items: center;


  &_container {
    background-color: #fff;
    width: 100%;
    margin-top: 15px;
    padding: 0 0 20px 0;
    box-sizing: border-box;
    box-shadow: 0 0 6px rgba(48, 48, 48, .1);
    border-radius: 5px;
  }

  &_header {
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.title {
  font-size: 20px;
  font-weight: 700;
  align-self: flex-start;
  margin: 0;

  &-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    @media screen and (max-width: 425px) {
      margin-top: 10px;
      flex-direction: column;
    }
  }
}

.empty-data {
  font-size: 20px;
  font-weight: 700;
  margin: auto;

}

.subtitle {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.pie {
  z-index: 0;

}

.chart_container {
  margin: 20px 0;
  overflow-x: scroll;
  min-width: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 310px;

  @media screen and (max-width:785px) {
    align-self: flex-start;
    justify-content: flex-start;
  }
}

.select {
  align-self: center;
  height: 26px;
  width: 200px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  outline: none;
}

.date-range-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &__invisible {
    display: none;
  }
}

.search-button {
  border: 1px solid #e0e0e0;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  height: 30px;
  padding: 6px 12px;
  transition: .3s ease-in-out;

  &:hover {
    color: rgba($color: #000000, $alpha: .7);
  }

  &:active {
    color: rgba($color: #000000, $alpha: .5);
    background-color: rgba(239, 239, 239, .5);
  }
}

.spinner {
  margin: 200px auto;
}