.form {
  padding: 24px 29px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1108px) {
    padding: 15px 15px 20px;
  }
}

.form_group {
  padding-top: 10px;
  display: flex;
  width: 100%;

  &__column {
    flex-direction: column;
    align-items: center;

    &>button,
    >p {
      align-self: center;
    }
  }

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

.form_group-link {
  cursor: pointer;
  font-size: 16px;
  padding-top: 10px;
  color: #464f3b;
  margin: 0;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.submit_button {
  margin-top: 20px;
  width: 210px;
  height: 50px;
  background-color: #464f3b;
  color: #fff;
  border: none;
  white-space: normal;

  &:hover {
    background-color: #343e29 !important;
  }
}

.title {
  font-weight: 700;
}

.text {}

.accent {
  font-weight: 700;
}

.spinner {
  margin: 50px auto;
}