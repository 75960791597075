.cookiesContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 27px 13px 15px 8px;
  width: 538px;
  box-shadow: 0px 0px 6px rgba(48, 48, 48, 0.1);
  color: #303030;
  position: fixed;
  z-index: 20;
  bottom: 100px !important;
  right: 60px;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
  & div {
    align-self: flex-end;
  }

  @media only screen and (max-width: 765px) {
    right: 0;
    left: 0;
    width: 100%;
    bottom: 0 !important;
    & div {
      align-self: center;
    }
  }
}

.text {
  display: block;
  max-width: 430px;
  width: 100%;
}

.cookiesLink {
  color: #464f3b;
  font-weight: 700;
  font-size: 16px;
  &:active,
  &:hover {
    color: darken(#464f3b, 10%);
  }
}

.content {
  display: flex;
  align-items: flex-start;

  @media only screen and (max-width: 765px) {
    margin-bottom: 24px;
  }
}

.cookiesImg {
  margin-right: 12px;
  margin-top: 10px;
  width: 73px;
  height: 73px;
}

.okBtn {
  padding: 9px 40px;
  color: #ffffff;
  background-color: #464f3b;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  &:active,
  &:hover {
    background-color: darken(#464f3b, 10%);
    color: #ffffff;
  }
}
