.tariffs-section {
  padding: 86px 0 155px;

  @media only screen and (max-width: 1108px) {
    padding: 25px 0 42px;
  }

  @media only screen and (max-width: 765px) {
    padding-bottom: 15px;
  }
}

.tariffs-section-title {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  line-height: 130.5%;
  text-transform: uppercase;
  margin: 0 0 50px 0;

  @media only screen and (max-width: 1108px) {
    margin-bottom: 10px;
    font-size: 25px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 20px;
    line-height: normal;
    text-align: left;
    margin-bottom: 25px;
  }
}

.line-break {
  @media only screen and (max-width: 1108px) {
    display: none;
  }
}

.button {
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.coworking {
  margin-bottom: 91px;

  &:last-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 1108px) {
    margin-bottom: 40px;
  }
}

.slide {
  height: 437px;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 1108px) {
    height: 382px;
  }

  @media only screen and (max-width: 765px) {
    height: 438px;
  }
}

.coworking__name {
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: #be763c;
  margin: 0 0 23px 0;

  &--beta-kovorking {
    color: #464f3b;
  }

  @media only screen and (max-width: 1108px) {
    text-align: center;
    margin-bottom: 17px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 18px;
    margin-bottom: 11px;
  }
}

.coworking__carousel {
  width: 340px;

  @media only screen and (max-width: 1108px) {
    width: 100%;
    height: 382px;
  }
}

.coworking__block {
  display: flex;
  align-items: stretch;

  @media only screen and (max-width: 1108px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 765px) {
    margin: 0 -15px;
  }
}

.coworking__tariffs {
  position: relative;
  background: #ffffff;
  border: 2px solid #be763c;
  flex-grow: 1;
  display: flex;

  @media only screen and (max-width: 1108px) {
    border-top: none;
  }

  @media only screen and (max-width: 765px) {
    flex-direction: column;
    border: none;
  }
}

.tariff {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19px 10px;
  padding-bottom: 25px;
  width: 25%;
  height: 433px;
  box-sizing: border-box;
  border-right: 1px solid #be763c;

  &:last-child {
    border: none;
  }

  @media only screen and (max-width: 1108px) {
    padding: 39px 13px;
    height: 350px;
  }

  @media only screen and (max-width: 765px) {
    flex-direction: row;
    height: auto;
    width: auto;
    border: none;
    border-bottom: 1px solid #be763c;

    &:last-child {
      border: none;
    }

    padding: 23px 0;
    margin: 0 20px;
  }
}

.tariff__text-wrapper {
  margin-left: 20px;
  flex-grow: 1;

  @media only screen and (max-width: 1108px) {
    margin: unset;
  }
}

.tariff__name {
  font-weight: 700;
  font-size: 18px;
  color: #303030;
  margin-bottom: 11px;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 1108px) {
    margin-bottom: 10px;
  }
}

.tariff__title {
  font-weight: 400;
  font-size: 16px;
  color: #c0c0c0;
  text-align: start;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 765px) {
    display: none;
  }
}

.tariff__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.tariff__price-wrapper {
  margin-bottom: 44px;
  position: relative;
  text-align: center;

  @media only screen and (max-width: 1108px) {
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 765px) {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }
}

.tariff__price {
  font-weight: 700;
  font-size: 24px;
  color: #303030;

  @media only screen and (max-width: 1108px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 765px) {
    margin-right: 9px;
  }
}

.tariff__price-per-day {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 18px;
  top: 30px;
  color: #c0c0c0;

  @media only screen and (max-width: 1108px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 765px) {
    position: relative;
    left: unset;
    top: unset;
    transform: none;
  }
}

.promo-sign {
  position: absolute;
  top: 15px;
  right: -10px;
  transform: translate(50%, -50%);

  @media only screen and (max-width: 1108px) {
    right: 9px;
  }

  @media only screen and (max-width: 765px) {
    display: none;
  }
}