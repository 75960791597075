%hover-border {
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #be763c;
  }

  &:hover {
    border-color: #be763c;
  }
}

.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.title {
  font-size: 20px;
  font-weight: 700;
  // margin-bottom: 15px;
  align-self: flex-start;
  margin: 0;
}

.form {
  display: flex;
  flex-direction: column;

  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(48, 48, 48, .1);
    margin-top: 15px;
  }

  &_group {
    display: flex;
    flex-direction: column;
    align-items: center;



    & label {
      width: 100%;
      max-width: 250px;
      line-height: 130%;
      font-weight: 700;
      font-size: 14px;
      color: #303030;
      align-self: center;

      &.settings {
        max-width: 180px;
      }

      @media only screen and (max-width: 768px) {
        font-size: 12px;
        align-self: flex-start;
      }
    }

    @media only screen and (max-width: 1108px) {
      flex-direction: column;
    }
  }
}

.field-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width:768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
}

.error {
  font-size: 14px;
  height: 24px;
  color: #f74646;
}

.form_control {
  border-radius: 0;
  max-width: 1000px;
  ;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  @extend %hover-border;
  height: 45px;
  font-size: 14px;

  &__error {
    border-color: #f74646;

    &:hover {
      border-color: #f74646;
    }

    &:focus {
      border-color: #f74646;
    }
  }

  &__select {
    // padding-left: 11px;
    padding-right: 9px;

    // appearance: none;
    // -webkit-appearance: none;
  }


  &>div {
    align-items: center;
    height: 43px !important;
    border: none !important;
  }



  @media only screen and (max-width: 765px) {
    font-size: 12px;
  }

}

input.form_control {
  padding: 0 9px 0 11px;
}

.inputs_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.buttons_container {
  display: flex;
  justify-content: space-between;
}

.button_save {
  font-size: 16px;
  height: 40px;
  grid-column: 2;
  grid-row: 1;
  justify-self: flex-end;
  cursor: pointer;
  background-color: #24cb15;
  border: 1px solid #24cb15;
  border-radius: 5px;
  color: #fff;
  padding: 0 12px;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    height: 35px;
    padding: 0 10px;
  }
}

.button_cancel {
  height: 40px;
  font-size: 16px;
  padding: 0 12px;
  grid-column: 1;
  grid-row: 1;
  cursor: pointer;
  background-color: #6c757d;
  border: 1px solid #6c757d;
  border-radius: 5px;
  color: #fff;
  justify-self: flex-start;


  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    height: 35px;
    padding: 0 10px;
  }
}

.alert {
  font-size: 14px;
  color: #f74646;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.alert-container {
  height: 24px;

  @media only screen and (max-width: 768px) {
    height: 17px;
  }
}

.input_skeleton {
  max-width: 330px;

  &__settings {
    max-width: 1000px;
  }
}