%hover-border {
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #be763c;
  }

  &:hover {
    border-color: #be763c;
  }
}

.form {
  padding: 24px 29px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1108px) {
    padding: 15px 15px 20px;
  }
}

.form_group {
  padding-top: 10px;
  display: flex;
  width: 100%;

  align-items: flex-start;
  justify-content: flex-start;

  &__column {
    flex-direction: column;
    align-items: center;

    &>button,
    >p {
      align-self: center;
    }
  }

  & label {
    font-weight: 400;
    width: 100%;
    padding-bottom: 10px;
    text-align: left;

    @media only screen and (max-width: 768px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 5px;
  }
}

.form_group-link {
  cursor: pointer;
  font-size: 16px;
  padding-top: 10px;
  color: #464f3b;
  margin: 0;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.form_control {
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  height: 50px;
  width: 100%;
  padding-left: 11px;
  cursor: pointer;
  @extend %hover-border;

  &::placeholder {
    color: #e0e0e0;
  }

  @media only screen and (max-width: 768px) {
    max-height: 45px;
  }
}

.checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 15px;

  &+label {
    cursor: pointer;
    font-size: 16px;

    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.submit_button {
  width: 125px;
  height: 50px;
  background-color: #464f3b;
  color: #fff;
  border: none;

  &:hover {
    background-color: #343e29 !important;
  }

  @media only screen and (max-width: 1108px) {
    width: 100px;
  }
}

.alert {
  font-size: 14px;
  color: #f74646;
  align-self: flex-start;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.alert-container {
  height: 24px;

  @media only screen and (max-width: 768px) {
    height: 17px;
  }
}