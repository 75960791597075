.modal_wrap {
  background-color: rgba(48, 48, 48, 0.9);
}

.modal_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 67px 63px;
  align-items: center;
  @media only screen and (max-width: 1108px) {
    padding: 52px 43px;
  }
}

.cabinet_button {
  width: 224px;
  height: 61px;
  background-color: #fff;
  border: 1px solid #303030;
  font-weight: 700;
  font-size: 18px;
  color: #303030;
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  @media only screen and (max-width: 1108px) {
    width: 200px;
    height: 50px;
  }
}
