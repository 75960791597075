%hover-border {
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #be763c;
  }

  &:hover {
    border-color: #be763c;
  }
}

.form {
  a {
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 25px;

  @media only screen and (max-width: 1108px) {
    padding: 15px 15px 20px;
  }
}

.form_group {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;

  &:first-of-type {
    margin-top: 0;
  }

  &>button {
    align-self: center;
  }

  &__column {
    flex-direction: column;
  }

  & label {
    font-weight: 400;
    width: 100%;
    text-align: left;

    @media only screen and (max-width: 768px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

.checkbox~label {
  cursor: pointer;
  display: inline;
  font-size: 14px;

  @media only screen and (max-width: 1108px) {
    font-size: 12px;
  }
}

.form_control {
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  height: 45px;
  width: 100%;
  padding-left: 11px;

  &::placeholder {
    color: #e0e0e0;
  }

  @extend %hover-border;
}

.checkbox {
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid #464f3b;
  margin-right: 12px;
  vertical-align: text-bottom;

  &+label {
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.submit_button {
  position: relative;
  width: 125px;
  height: 50px;
  background-color: #464f3b;
  color: #fff;
  border: none;

  &:hover:not([disabled]) {
    background-color: #343e29 !important;
  }

}

.alert {
  height: fit-content;
  font-size: 14px;
  color: #f74646;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.alert-container {
  height: 24px;

  @media only screen and (max-width: 768px) {
    height: 17px;
  }
}

.popup {
  position: absolute;
  box-sizing: border-box;
  top: 280px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 6px 0px rgba(48, 48, 48, 0.10);
  background-color: #fff;
  width: 400px;
  padding: 20px;

  &_invisible {
    display: none;
  }

  &-title {
    margin: 10px 0 0 0;
    font-size: 16px;
    color: #be763c;
    text-align: center;
  }

  &-button {
    align-self: center;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 200px;
    height: 50px;
    background-color: #464f3b;
    color: #fff;
    border: none;
  }
}

.confirm-text {
  margin: 20px 0 0 0;
  font-size: 14px;

  &-accent {
    font-weight: 700;
  }
}

.resend-text {
  margin: 10px 0 0 0;
  font-size: 14px;
}