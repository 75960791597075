%transition {
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.85;
  }
}

.tabletHeader {
  display: none;
  @media only screen and (max-width: 1108px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 36px 0 84px 0;
    z-index: 1;
    @media only screen and (max-width: 765px) {
      margin: 25px 0 90px 0;
    }
    &.cabinetHeader {
      margin-top: 0;
      background-color: #303030;
      padding: 25px 16px 19px 16px;
    }
    &.loginHeader {
      display: none;
      @media screen and (max-width: 765px) {
        display: flex;
        padding: 0 15px;
        box-sizing: border-box;
        margin-bottom: 25px;
      }
    }
  }
}
.logo {
  fill: white;
  width: 82px;
  height: 44.22px;
  @extend %transition;
  @media only screen and (max-width: 765px) {
    width: 55px;
    height: 30px;
    &.cabinetLogo {
      width: 55px;
      height: 30px;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  margin: 0 2px 10px 0;
}

.tabletHeaderButton {
  background-color: transparent;
  margin-right: 14px;
  &:hover,
  &:active {
    background-color: transparent;
    opacity: 0.7;
  }
  @media only screen and (max-width: 765px) {
    display: none;
  }
}

.hamburgerBtn {
  @extend %transition;
  cursor: pointer;
  display: block;
  width: 30px;
  height: 14px;
  background: url('./images/hanburger.svg') center no-repeat;
}
