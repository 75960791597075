%hover-border {
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #be763c;
  }

  &:hover {
    border-color: #be763c;
  }
}

.edit {
  display: flex;
  grid-row-gap: 32px;
  grid-column-gap: 30px;
  padding: 40px 0 65px;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 1108px) {
    padding: 39px 0 70px;
    grid-column-gap: 20px;
    max-width: 765px;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: 765px) {
    grid-template-columns: 1fr;
    padding: 22px 0 45px;
    width: 100%;
    max-width: 380px;
    gap: 20px;
  }
}

// Layout
.one {
  align-self: flex-start;
}

.three {
  grid-column: 2;
  grid-row: 2 / 3;

  @media only screen and (max-width: 765px) {
    grid-column: 1;
    grid-row: 3;
  }
}

.section_header {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;

  @media only screen and (max-width: 1108px) {
    font-size: 16px;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 765px) {
    font-size: clamp(16px, 5vw, 20px);
  }
}

.section_description {
  font-size: 14px;

  @media only screen and (max-width: 765px) {
    font-size: 12px;
  }
}

.section_main {
  width: 100%;
  display: grid;
}

.section_settings {
  width: 100%;
}


.section_top {
  padding: 18px 25px;
  border-bottom: 1px solid #c0c0c0;

  font-weight: 700;
  font-size: 18px;

  @media only screen and (max-width: 1108px) {
    padding: 15px 17px;
    font-size: 16px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 14px;
  }
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;



  @media screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
  }
}

.form_container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(48, 48, 48, 0.1);

  &_one {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  &_two {
    grid-column: 2;
    grid-row: 1;
  }

  &__settings {
    grid-column: 1/3;
    grid-row: 3;
  }

}

.inputs_container {
  padding: 26px 48px 59px 25px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media screen and (max-width: 1108px) {
    padding: 23px 17px 30px;
  }
}

.form_group {
  display: flex;
  flex-direction: column;
  align-items: center;

  & label {
    width: 100%;
    max-width: 118px;
    line-height: 130%;
    font-weight: 700;
    font-size: 14px;
    color: #303030;
    align-self: center;

    &.settings {
      max-width: 180px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 12px;
      align-self: flex-start;
    }
  }

  @media only screen and (max-width: 1108px) {
    flex-direction: column;
  }
}

.field-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width:768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.error {
  font-size: 14px;
  height: 24px;
  color: #f74646;
}

.form_control {
  border-radius: 0;
  max-width: 330px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  @extend %hover-border;
  height: 45px;
  font-size: 14px;

  &__error {
    border-color: #f74646;

    &:hover {
      border-color: #f74646;
    }

    &:focus {
      border-color: #f74646;
    }
  }

  &__select {
    padding-right: 9px;
  }

  &__settings {
    max-width: 1000px;
    ;
  }

  &>div {
    align-items: center;
    height: 43px !important;
    border: none !important;
  }

  @media only screen and (max-width: 1108px) {
    max-width: 100%;
  }

  @media only screen and (max-width: 765px) {
    font-size: 12px;
  }

}

input.form_control {
  padding: 0 9px 0 11px;
}

.date {
  max-width: 71%;
  font-size: 14px;
  margin-top: -10px;
  align-self: center;

  &__settings {
    max-width: 1000px;
  }

  @media only screen and (max-width: 1108px) {
    align-self: flex-start;
    margin-top: 1px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 12px;
  }
}

.button_save {
  font-size: 16px;
  height: 40px;
  grid-column: 2;
  grid-row: 4;
  justify-self: flex-end;
  cursor: pointer;
  background-color: #24cb15;
  border: 1px solid #24cb15;
  border-radius: 5px;
  color: #fff;
  padding: 0 12px;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

.button_cancel {
  height: 40px;
  font-size: 16px;
  padding: 0 12px;
  grid-column: 1;
  grid-row: 4;
  cursor: pointer;
  background-color: #6c757d;
  border: 1px solid #6c757d;
  border-radius: 5px;
  color: #fff;
  justify-self: flex-start;


  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

.alert {
  font-size: 14px;
  color: #f74646;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.alert-container {
  height: 24px;

  @media only screen and (max-width: 768px) {
    height: 17px;
  }
}

.input_skeleton {
  max-width: 330px;

  &__settings {
    max-width: 1000px;
  }
}