.compare-section {
  background-color: #e5e5e5;
  padding: 104px 0;

  @media only screen and (max-width: 1108px) {
    padding: 74px 0;
    padding-bottom: 39px;
  }

  @media only screen and (max-width: 765px) {
    padding: 25px 0;
  }
}

.compare-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.compare-section__title {
  &:first-child {
    line-height: 35px;
  }

  &:last-child {
    margin-top: 15px;
  }

  @media only screen and (max-width: 1108px) {
    font-size: 25px;
    line-height: normal;

    &:last-child {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 765px) {
    font-size: 20px;
    order: 0;
  }
}

.compare-diagram-title {
  align-self: flex-start;
  font-size: 35px;
  font-weight: 700;
  line-height: 130.5%;
  text-transform: uppercase;
  margin: 0 0 81px 0;

  @media only screen and (max-width: 1108px) {
    margin-bottom: 7px;
    font-size: 25px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 20px;
  }
}

.compare-diagram-pic {
  margin-bottom: 75px;
  width: 100%;
  grid-column: 1;

  @media only screen and (max-width: 1108px) {
    margin-bottom: 43px;
    max-width: 308px;
    max-height: 295px;
  }

  @media only screen and (max-width: 765px) {
    order: 2;
    max-width: 420px;
    margin-bottom: 30px;
  }
}

.compare-subtitle-wrapper {
  @media only screen and (max-width: 765px) {
    order: 6;
  }
}

.compare-subtitle,
.first-promo-subtitle {
  font-size: 16px;
  text-align: start;
  line-height: 24.3px;
  margin: 0 0 14px 0;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
    max-width: 277px;
  }
}

.compare-subtitle {
  grid-column: 1;
  grid-row: 3;

  @media only screen and (max-width: 765px) {
    margin-bottom: 0;
    max-width: none;
    width: 100%;
  }
}

.compare-subtitle--addition {
  @media only screen and (max-width: 1108px) {
    display: block;
    font-weight: normal;
    margin-top: 6px;
    line-height: 150%;
    margin-bottom: 8px;
  }
}

.compare-subtitle--height {
  max-width: 450px;
  margin: 0 0px 89px 0;
  grid-column: 2;
  grid-row: 1;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
    margin: 8px 0 35px 0;
    line-height: 18.9px;
  }

  @media only screen and (max-width: 765px) {
    margin-bottom: 18px;
    line-height: 19px;
    margin-right: auto;
  }
}

.diagram-compare {
  margin-bottom: 89px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;

  @media only screen and (max-width: 1108px) {
    margin-bottom: 80px;
    column-gap: 24px;
  }

  @media only screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
}

.compare-table {
  display: flex;
  flex-direction: column;
  border: 2px solid #be763c;
  margin-bottom: 144px;
  grid-column: 2;
  max-height: 444px;

  @media screen and (max-width: 1108px) {
    font-size: 10px;
    border-width: 1px;
    align-self: flex-start;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 765px) {
    order: 4;
    margin-bottom: 25px;
    align-self: center;
  }
}

.compare-table-subtitle {
  @media only screen and (max-width: 765px) {
    order: 5;
    margin-bottom: 14px;
  }
}

.compare-table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.compare-table-row--header {
  font-size: 18px;
  height: 60px;
  font-weight: 700;
  border-bottom: 2px solid #c0c0c0;

  @media screen and (max-width: 1108px) {
    border-width: 1px;
    font-size: 11px;
    height: 32px;
  }
}

.compare-table-row--footer {
  border-top: 2px solid #c0c0c0;
  font-weight: 700;
  font-size: 18px;
  height: 60px;

  @media screen and (max-width: 1108px) {
    font-size: 10px;
    height: 32px;
    border-width: 1px;
  }
}

.compare-table-item {
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-right: 2px solid #c0c0c0;

    @media only screen and (max-width: 1108px) {
      border-width: 1px;
    }
  }

  &:last-child {
    border-left: 2px solid #be763c;

    @media only screen and (max-width: 1108px) {
      border-width: 1px;
    }
  }
}

.compare-table-item-text {
  padding: 6px 20px 4px 20px;
  justify-content: flex-start;

  @media only screen and (max-width: 1108px) {
    padding: 0px 11px;
    line-height: 18.5px;
  }
}

.button {
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  &-wrapper {
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1108px) {
      justify-content: center;
      grid-column: -1 / -3;
      margin-top: 10px;
    }

    @media only screen and (max-width: 765px) {
      order: 7;
    }
  }
}

.compare-section-text {
  font-size: 16px;
  line-height: 150%;
  margin: 0 0 12px 0;

  &:last-child {
    margin: 0;
  }

  @media screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

.compare-cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 108px;

  @media only screen and (max-width: 1108px) {
    margin-bottom: 106px;
  }

  @media only screen and (max-width: 765px) {
    margin-bottom: 25px;
  }
}

.compare-cards-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 130.5%;
  text-transform: uppercase;
  margin: 0 0 80px 0;

  @media only screen and (max-width: 1108px) {
    margin-bottom: 34px;
    font-size: 25px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 20px;
    margin-bottom: 25px;
  }
}

.compare-cards {
  margin-bottom: 43px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, auto);
  grid-auto-flow: column;

  @media only screen and (max-width: 1108px) {
    margin: 0 -15px;
    margin-bottom: 25px;
  }

  @media only screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
  }
}

.compare-card {
  display: contents;

  &>* {
    background-color: #ffffff;
    padding: 0 36px;
  }

  @media only screen and (max-width: 765px) {
    display: flex;
    flex-direction: column;
  }
}

.compare-card--left {
  @media only screen and (max-width: 765px) {
    margin-bottom: 40px;
  }

  &>* {
    border-right: 12px solid #e5e5e5;

    @media only screen and (max-width: 1108px) {
      border-width: 12px;
    }

    @media only screen and (max-width: 765px) {
      border: none;
    }
  }
}

.compare-card--right {
  @media only screen and (max-width: 765px) {
    margin-bottom: 40px;
  }

  &>* {
    border-left: 12px solid #e5e5e5;

    @media only screen and (max-width: 1108px) {
      border-width: 12px;
    }

    @media only screen and (max-width: 765px) {
      border: none;
    }
  }
}

.compare-card__header-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 19px 0;
  padding-top: 38px;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 1108px) {
    margin-bottom: 20px;
  }
}

.compare-card__title {
  margin: 0 0 5px 0;
  font-size: 16px;

  @media only screen and (max-width: 1108px) {
    margin-bottom: 19px;
    font-size: 14px;
  }

  @media only screen and (max-width: 765px) {
    order: 1;
  }
}

.compare-card__subtitle {
  margin: 0 0 22px 0;
  font-size: 16px;

  @media only screen and (max-width: 1108px) {
    font-size: 14px;
  }
}

.compare-card__header-title {
  text-align: center;
}

.compare-card-pic {
  width: 100%;
  margin-bottom: 35px;

  @media only screen and (max-width: 765px) {
    height: 186px;
  }
}

.compare-card-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.compare-card-img {
  width: 100%;
  max-width: 451px;
  align-self: center;
  height: auto;
  margin-bottom: 35px;

  @media only screen and (max-width: 1108px) {
    margin-bottom: 27px;
  }
}

.negation-item {
  display: flex;
  margin-bottom: 19px;
  align-items: flex-start;

  &__title {
    font-size: 16px;
    margin: 0;

    @media screen and (max-width: 1108px) {
      font-size: 14px;
    }
  }
}

.negation-icon {
  margin-right: 11px;
  flex-shrink: 0;
}

.first-promo-title-wrapper {
  align-self: flex-start;
  margin-bottom: 30px;

  @media only screen and (max-width: 1108px) {
    margin-bottom: 10px;
  }
}

.first-promo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: 1108px) {
    margin: 0 -65px;
  }

  @media only screen and (max-width: 765px) {
    display: flex;
    margin: 0 -15px;
  }
}

.first-promo-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 130.5%;
  margin: 0 0 5px 0;
  text-transform: uppercase;

  @media screen and (max-width: 1108px) {
    font-size: 25px;
    margin-bottom: 13px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 20px;
    text-align: start;
  }
}

.first-promo__pic {
  overflow: hidden;
  height: 674px;

  @media only screen and (max-width: 1108px) {
    height: 466px;
  }

  @media only screen and (max-width: 765px) {
    display: none;
  }
}

.first-promo__img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.first-promo__text-content {
  background: #1d1d1b;
  padding: 62px 40px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  line-height: 130.5%;
  align-items: flex-start;

  @media only screen and (max-width: 1108px) {
    padding: 85px 26px 24px 20px;
  }

  @media only screen and (max-width: 765px) {
    height: 80vh;
    padding: 56px 20px;
    padding-bottom: 39px;
    background-image: linear-gradient(rgba(29, 29, 27, 0.8),
        rgba(29, 29, 27, 0.8)),
      url('./images/first_promo.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
}

.first-promo__text-content-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 130.5%;
  text-transform: uppercase;
  text-align: start;
  line-height: 46px;
  margin: 0 0 34px 0;

  @media only screen and (max-width: 1108px) {
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
}

.first-promo-subtitle--addition {
  font-size: 16px;
  margin-top: auto;

  @media only screen and (max-width: 1108px) {
    font-size: 12px;
    line-height: 136%;
  }
}

.first-promo-subtitle--wider {
  font-size: 18px;
}

.tick {
  @media only screen and (max-width: 1108px) {
    height: 10px;
  }
}