.section-header {
  border-bottom: 1px solid black;
  background-color: #303030;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.header {
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  @media only screen and (max-width: 1108px) {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: 765px) {
    padding-bottom: 19px;
    padding-top: 24px;
  }
}

.popover {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px 50px 0px 12px;

  &-link {
    cursor: pointer;
    color: #303030;
    transition: color 0.2s ease-in-out;

    &:not(:last-child) {
      padding-bottom: 10px;
    }

    &:hover,
    &:focus,
    &:active {
      color: #be763c;
    }
  }
}

.userContainer {
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;

}

.headerUser {
  margin-right: 20px;
  color: #fff;
  transition: color 0.2s ease-in-out;

  @media only screen and (max-width: 1108px) {
    display: none;
  }

  &:hover {
    color: #be763c;
  }
}

.headerButton {
  width: 39px;
  height: 39px;
  background-color: #be763c;
  border-radius: 50%;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    background-color: #ac662d;
  }
}

.logo {
  width: 64px;
  height: 35px;
  margin: 11px 0;
  fill: #fff;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
    transform: scale(1.05)
  }


  @media only screen and (max-width: 765px) {
    width: 55px;
    height: 30px;
  }

  &-container {
    display: flex;
    justify-content: center;
  }
}

.hamburger {
  margin-right: 20px;
  background-color: #be763c;
  box-shadow: none !important;

  &:hover {
    background-color: #ac662d !important;
  }

  &:active {
    background-color: rgba($color: #ac662d, $alpha: .7) !important
  }
}