.cabinet_main {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 29px 29px;
  padding: 40px 0 100px;

  @media only screen and (max-width: 1205px) {
    grid-template-columns: auto auto auto auto;
    padding: 39px 0 79px;
    gap: 21px;
    max-width: 765px;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: 765px) {
    grid-template-columns: 1fr;
    padding: 22px 0 39px;
    justify-items: center;
    width: 100%;
    max-width: 380px;
    gap: 15px;
  }
}

// Layout
.one {
  grid-column: 1 / 5;
  grid-row: 1;

  @media only screen and (max-width: 1205px) {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 1 / 3;
    grid-row: 1;
  }
}

.two {
  grid-column: 5;
  grid-row: 1;

  @media only screen and (max-width: 1205px) {
    grid-column: 2 / 5;
    grid-row: 1 / 1;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 1;
    grid-row: 3;
  }
}

.three {
  grid-column: 5 / 5;
  grid-row: 2 / 6;

  @media only screen and (max-width: 1205px) {
    grid-column: 2 / 5;
    grid-row: 2 / 5;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 1 / 3;
    grid-row: 2;
  }
}

.four {
  grid-column: 1;
  grid-row: 2 / 3;

  @media only screen and (max-width: 1205px) {
    grid-row: 3;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 1 / 3;
    grid-row: 4;
  }
}

.five {
  grid-column: 2 / 5;
  grid-row: 2 / 3;

  @media only screen and (max-width: 1205px) {
    grid-column: 1;
    grid-row: 4;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 1 / 3;
    grid-row: 5;
  }
}

.six {
  grid-column: 1;
  grid-row: 3 / 6;

  @media only screen and (max-width: 1205px) {
    grid-column: 1;
    grid-row: 5 / 7;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 1 / 3;
    grid-row: 6;
  }
}

.seven {
  grid-column: 2 / 5;
  grid-row: 3 / 6;

  @media only screen and (max-width: 1205px) {
    grid-column: 2 / 5;
    grid-row: 5 / 7;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 1/3;
    grid-row: 7;
  }
}

.eight {
  grid-column: 4;
  grid-row: 5;

  @media only screen and (max-width: 1205px) {
    grid-column: 4;
    grid-row: 6;
  }

  @media only screen and (max-width: 765px) {
    grid-column: 2;
    grid-row: 3;
  }
}

// Main styles
.cabinet_qr {
  display: flex;
  flex-direction: column;
  max-width: 348px;
  margin: 0 auto;

  @media only screen and (max-width: 765px) {
    width: 100%;
  }
}

.qr {
  width: 348px;
  height: 348px;

  @media only screen and (max-width: 1205px) {
    width: 306px;
    height: 306px;
  }

  @media only screen and (max-width: 765px) {
    width: 100%;
    min-width: 291px;
    height: auto;
  }
}

.section_header {
  font-size: 18px;
  font-weight: 700;

  @media only screen and (max-width: 1205px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 765px) {
    font-size: clamp(16px, 5vw, 20px);
  }
}

.header-qr {
  @media only screen and (max-width: 765px) {
    font-size: 12px;
  }
}

.section_header-name {
  color: #be763c;
}

.section_description {
  font-size: 14px;

  @media only screen and (max-width: 765px) {
    font-size: 12px;
    line-height: 130.5%;
    display: block;
  }
}

.section_block-item {
  width: 349px;
  box-sizing: border-box;
  max-height: 189px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(48, 48, 48, 0.1);
  border: none;
  padding: 18px 12px;
  color: #303030;
  margin: 0 auto;

  @media only screen and (max-width: 1205px) {
    width: 308px;
    height: 165px;
    padding: 15px 12px;
  }

  @media only screen and (max-width: 765px) {
    padding: 14px 12px;
    width: 100%;
    max-width: 388px;
    min-width: 290px;
    font-size: clamp(12px, 3.75vw, 16px);
    font-size: 12px;
  }
}

.item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 765px) {
    font-size: clamp(12px, 3.75vw, 16px);
  }
}

.header-title {
  font-weight: 700;
  font-size: 18px;
  color: #303030;
  display: flex;
  gap: 10px;
  align-items: center;

  @media only screen and (max-width: 1205px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 765px) {
    font-size: clamp(12px, 3.75vw, 16px);
  }
}

.header-action {
  transition: color 0.2s ease-in-out;
  color: #303030;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: #be763c;
  }

  @media only screen and (max-width: 765px) {
    font-size: 12px;
  }
}

.header-img {
  width: 31px;
  height: 31px;
  border-radius: 50%;

  background-image: url(./images/Balance.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #464f3b;

  &.balance {
    background-image: url(./images/Balance.svg);
  }

  &.membership {
    background-image: url(./images/Membership.svg);
  }

  &.social {
    background-image: url(./images/Social.svg);
  }
}

.item__body {
  display: flex;
  flex-direction: column;

  &.balance {
    padding: 26px 20px;
    text-align: center;

    @media only screen and (max-width: 1205px) {
      padding: 5px 20px;
    }

    @media only screen and (max-width: 765px) {
      padding: 0 20px;
    }
  }

  &.membership {
    display: flex;
    flex-direction: row;
    padding: 15px 0;
    box-sizing: border-box;
    height: 100%;
    justify-content: space-between;

    @media only screen and (max-width: 765px) {
      padding: 5px 0;
      justify-content: space-between;
    }
  }

  &.social {
    padding: 0 39px;
    gap: 10px;

    @media only screen and (max-width: 765px) {
      gap: 1vw;
    }
  }

  &.promo {
    gap: 55px;

    @media only screen and (max-width: 1205px) {
      gap: 35px;
    }

    @media only screen and (max-width: 765px) {
      gap: 25px;
    }
  }
}

.item__body-coworking {
  display: block;
  margin-top: 4px;

  @media only screen and (max-width: 765px) {
    font-size: 14px;
  }
}

.item__body-column {
  display: flex;
  margin: auto;

  &:last-of-type {
    margin-right: 22px;
  }

  @media only screen and (max-width: 765px) {
    width: auto;
  }

  img {
    align-self: start;
    margin-right: 4px;
  }
}

.item__empty-subscriptions {
  margin: auto;
  justify-self: center;
  padding: 0;
}

.item__body-bold {
  font-weight: 700;
  font-size: 35px;
  text-transform: uppercase;

  @media only screen and (max-width: 765px) {
    font-size: 30px;
    padding-top: 12px;
  }
}

.item__body-data {
  display: flex;
  flex-direction: column;
  color: #000000;
  align-items: baseline;
}

.item__body-fade {
  color: #c0c0c0;
  font-size: 14px;

  @media only screen and (max-width: 765px) {
    font-size: 12px;
  }

  line-height: 130%;

  &_date {
    align-self: center;
    text-align: center;
    max-width: 240px;
    margin-top: 12px;

    @media only screen and (max-width: 765px) {
      margin-top: 8px;
    }
  }

  &_koworkings {
    text-align: start;
  }

  &_marginless {
    margin: 0;
  }

  &_remains {
    margin-top: 7px;
  }
}

.item__body-days {
  font-weight: 700;
  line-height: 46px;
}

.item__body-place {
  display: flex;
  justify-content: space-between;
}

.section_block-item__promo {
  width: 349px;

  @media only screen and (max-width: 765px) {
    width: 100%;
    max-width: 388px;
    min-width: 290px;
    font-size: clamp(12px, 3.75vw, 16px);
  }
}

.promo_title {
  font-weight: 700;
  font-size: 18px;
  color: #303030;

  @media only screen and (max-width: 765px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 14px;
  }
}

.promo_form {
  margin-top: 8px;
  display: inline-flex;
}


.promo_input {
  border-radius: 0;
  cursor: pointer;
  height: 50px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #c0c0c0;
  width: calc(100% - 110px);
  padding: 10px 20px;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #be763c;
  }

  &:hover {
    border-color: #be763c;
  }

  @media only screen and (max-width: 1205px) {
    width: calc(100% - 100px);
  }

  &::placeholder {
    color: #7e7e7e;
  }
}

.promo_button {
  width: 110px;
  height: 50px;
  background-color: #464f3b;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background-color: #343e29;
  }

  @media only screen and (max-width: 1205px) {
    width: 100px;
  }
}

.promo_button-invite {
  font-size: 16px;
  width: 173px;
  height: 50px;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: #c0c0c0;
    color: #fff;
  }

  @media only screen and (max-width: 1205px) {
    font-size: 14px;
  }
}

.promo_button-question {
  width: 13px;
  height: 13px;

  background-image: url(./images/Question.svg);
  background-repeat: no-repeat;
  background-position: center center;

  position: absolute;
  left: 177px;
  cursor: pointer;
}

.item__footer {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.alert {
  height: fit-content;
  font-size: 14px;
  color: #f74646;


  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}