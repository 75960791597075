.gallery {
  background-color: #f8f7f6;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  @media only screen and (max-width: 1108px) {
    padding: 57px 0 45px;
  }
  @media only screen and (max-width: 765px) {
    padding: 38px 0 25px;
  }
}
.gallery__title {
  font-size: 35px;
  font-weight: 700;
  line-height: 130.5%;
  text-transform: uppercase;
  margin: 0 0 44px 0;
  text-align: center;
  @media only screen and (max-width: 1108px) {
    font-size: 25px;
    margin-bottom: 28px;
  }

  @media only screen and (max-width: 765px) {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.gallery-switcher {
  display: flex;
  margin: 0 auto 40px;
  @media only screen and (max-width: 1108px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 765px) {
    margin-bottom: 22px;
  }
}
.gallery-switcher__button {
  padding: 32px 48px;
  background-color: #ffffff;
  border: 2px solid #c0c0c0;
  color: #303030;
  outline: none;
  &:hover {
    border-color: transparent;
  }
  &:active {
    border-color: transparent;
  }
  &:focus {
    border-color: transparent;
  }
  &--active {
    border-color: transparent;
    background-color: #464f3b;
    color: #ffffff;
  }
  @media only screen and (max-width: 1108px) {
    padding: 23px 16px;
  }
}
.slider {
  display: none;
  opacity: 0;
  &--active {
    display: block;
    opacity: 1;
  }
  @media only screen and (max-width: 1108px) {
    margin: 0 -65px;
  }
  @media only screen and (max-width: 765px) {
    margin: 0;
  }
}
.slideTitle {
  text-align: center;
  height: 52px;
  display: flex;
  align-items: center;
  color: #303030;
  @media only screen and (max-width: 1108px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 765px) {
    height: 80px;
  }
}
