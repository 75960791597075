.modal_wrap {
  background-color: rgba(48, 48, 48, 0.9);
}

.modal_header {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 17px;
  & + svg {
    margin: 0 auto !important;
  }
}

.qr-container {
  display: flex;
  justify-content: center;
}

.modal_body {
  align-items: center;
  width: 348px;
  height: 348px;

  @media only screen and (max-width: 765px) {
    width: 250px;
    height: 250px;
  }
}
